.detail_gallery_main {
    width: 100%;
}

.large_img_main {
    width: 100%;
    position: relative;
}

.large_img {
    width: 100%;
}

.large_img img {
    position: relative;
    -webkit-transition: opacity .3s ease-out;
    transition: opacity .3s ease-out;
    width: 100%;
    height: auto;
    will-change: opacity;
}

.varient_list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.varient_box {
    width: 50%;
    overflow: hidden;
    background: url(../../../assets/slider/detail_bg3.png) no-repeat center center;
    background-size: cover;
    position: relative;
}

.varient_box:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 69.0625%;
}

.varient_bg1:before,
.varient_bg2:before {
    padding-top: 76.95853%;
}

.varient_interior:before {
    padding-top: 38.47926%;
}

.varient_box span {
    display: block;
}

.varient_box img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

.varient_bg1 {
    background: url(../../../assets/slider/detail_bg1.png) no-repeat center center;
    background-size: cover;
}

.varient_bg2 {
    background: url(../../../assets/slider/detail_bg2.png) no-repeat center center;
    background-size: cover;
}

.varient_interior {
    width: 100%;
    min-height: 700px;
}

.box_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.varient_bg1 img {
    width: 100%;
    height: 100%;
    top: -16%;
    right: -8%;
    object-fit: cover;
    object-position: center center;
}

.varient_bg2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    position: relative;
    transform: scale(1.4);
    top: 70px;
}

.large_img_box {
    position: relative;
}

.left_icons {
    width: 40px;
    position: absolute;
    left: 10px;
    top: 25px;
}

.left_icon {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background: #F6F6F6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.left_icon.active,
.left_icon:hover {
    background-color: #E1E1E1;
}

.left_icon svg {
    opacity: 0.5;
}

.left_icon.active svg,
.left_icon:hover svg {
    opacity: 1;
}

.left_icon_ext {
    border-radius: 3px 3px 0px 0px;
}

.left_icon_int {
    border-radius: 0px 0px 3px 3px;
    margin-bottom: 48px;
}

.view_btns {
    width: 200px;
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%);
    border-radius: 30px;
    overflow: hidden;
    display: flex;
}

.view_btns button {
    width: 50%;
    font-size: 12px;
    line-height: 1.2em;
    color: #000;
    font-weight: 500;
    background: #fff;
    display: block;
    border: 0;
    text-align: center;
    padding: 10px 16px;
    transition: all 0.175s ease;
}

.view_btns button:hover,
.view_btns button.active {
    background: #000;
    color: #fff;
}

@media (max-width: 575px) {
    .varient_bg2 img {
        top: 28px;
    }
}