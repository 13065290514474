
.summary_detail{ padding: 80px 40px; width: 100%; }

.print_link span{ padding-left: 10px;  }

.seriesheader{ width: 100%; padding-bottom: 60px; }

.summary_items{ width: 100%; padding-bottom: 60px;}

.summary_items ul{ display: flex; flex-wrap: wrap; justify-content: space-between; }

.summary_items ul li{ width: 44%; padding-bottom: 25px; }

.summary_items ul li h4{ padding-bottom: 10px; font-size: 12px;  font-weight: 700; letter-spacing: 2px; color: #221f1f; line-height: 1.4em; text-transform: uppercase; }

.summary_items ul li p{ font-size: 16px;  letter-spacing: .25px; font-weight: 400; line-height: 1.4em; color: #414141; }


.summary_items h4 span{ display: inline-block; vertical-align: text-top; }


hr{ border: 0;  outline: 0; opacity: 1;; width: 100%; height: 1px; margin: 38px 0 0; background-color: #cacaca; }

.options_detail{ width: 100%;}

.textBtnRow{ width: 100%; margin-top: 30px; }


@media (min-width: 992px) and (max-width: 1199.98px) {
    .summary_detail{ padding:30px 15px; }
    .seriesheader{ padding-bottom: 20px; }
    .summary_items{ padding-bottom: 20px; }
    .summary_items ul li { padding-bottom: 15px; }
    .summary_items ul li h4{ padding-bottom: 5px; }
}

@media (min-width: 768px) and (max-width: 991.98px) { 
    .summary_detail{ padding:30px 15px; }
    .seriesheader{ padding-bottom: 20px; }
    .summary_items{ padding-bottom: 20px; }
    .summary_items ul li { padding-bottom: 15px; }
    .summary_items ul li h4{ padding-bottom: 5px; }
}


@media (max-width: 767.98px) {

.summary_detail{ padding:30px 15px; }
.seriesheader{ padding-bottom: 20px; }
.summary_items{ padding-bottom: 20px; }
.summary_items ul li { padding-bottom: 15px; }
.summary_items ul li h4{ padding-bottom: 5px; }
.summary_items ul li p{ font-size: 13px; }

}








