.mg_modal-dialog {
  max-width: 860px !important;
}

.mg_img-position {
  height: 100% !important;
}

.mg_modal-left {
  height: 100%;
}

.mg_modal-left img {
  display: block;
  width: 100%;
  height: 100%;
  max-width: none;
  object-fit: cover;
}

.mg_modal-left-content {
  color: #FFF;
  position: absolute;
  left: 0px;
  bottom: 0px;
  padding: 30px;
}

.mg_modal-left-content h1 {
  font-size: 28px;
  font-weight: 200;
}

.mg_modal-left-content ul li {
  color: #FFF;
  font-size: 12px;
  list-style: disc;
  padding: 5px;
  font-weight: 200;

}

.mg_modal-form-text,
.mg_modal-custom-text {
  color: #4D4D4D;
  font-size: 12px;
  font-weight: 400;
}

.mg_signin-btn {
  text-decoration: none;
  color: black;
  font-weight: 600;
}

.svh_modal_content {
  width: 100%;
}

.svh_modal_content h2 {
  color: #000;
  padding-bottom: 8px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 0.45px;
}

.svh_modal_content h3 {
  color: #000;
  padding-bottom: 8px;
  font-size: 10px;
  font-weight: 300;
  letter-spacing: 0.52px;
}

.nba_modelbox .modal-dialog {
  max-width: 980px !important;
}

/* amir css */

.md_radio {
  display: flex;
  align-items: center;
  padding: 15px 12px;
  cursor: pointer;
  color: #000;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.4em;
  letter-spacing: 0.72px;
  gap: 5px;
}

.md_radio .md_radio__button {
  position: relative;
  cursor: pointer;
  margin: 0 6px;
}

.md_radio [type=radio] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.md_radio .md_radio__button::before,
.md_radio .md_radio__button::after {
  content: '';
  box-sizing: border-box;
  display: block;
  transition: all 100ms cubic-bezier(0.4, 0.0, 0.2, 1);
}

.md_radio .md_radio__button::before {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  border: 0.40em solid #BDBDBD;
}

.md_radio .md_radio__button::after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  transform-origin: 50%, 50%;
  transform: scale(0, 0) translate(-50%, -50%);
  background: #1C69D4;
}

.md_radio [type=radio]:checked+.md_radio__button::after {
  transform: scale(1, 1) translate(-50%, -50%);
}

.md_radio [type=radio]:checked+.md_radio__button::before {
  border-color: #C7C7C7;
  background-color: #1C69D4;
}

.md_radio [type=radio]:disabled+.md_radio__button {
  cursor: not-allowed;
  filter: grayscale(100%);
  opacity: 0.6;
}

.md_radio [type=radio]:focus+.md_radio__button {
  outline: none;
  box-shadow: none;
}

label.md_radio {
  font-size: 12px;
  font-weight: 400;
}

.fpi_mainheading,
.fpi_quiz_sec {
  padding: 15px 80px 0px 40px;
}

.fpi_mainheading h2 {
  font-weight: 300;
  font-size: 32px;
  letter-spacing: 0.5px;
  color: #000000;

  margin: 0;
}

.subheading h3 {
  font-weight: 300;
  font-size: 19px;
  letter-spacing: 0.5px;
  color: #000000;
  padding: 22px 0px 12px 0px;
}

.continue-btn button {
  font-weight: 600;
  font-size: 15px;
}

.continue-btn button svg {
  font-size: 15px;
}

.continue-btn {
  display: flex;
  justify-content: flex-end;
}

.fpi_mainheading p {
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.5px;
  color: #5C5C5C;
  padding: 15px 0px 0px 0px;
}

.fpi_list_items ul {

  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.fpi_list_items ul li {
  width: calc(50% - 10px);
  border: 1px solid #F2F2F2;
  transition: all 0.3s ease;
}

.fpi_list_items ul li a {
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 17px;
  color: #221f1f;
  padding: 10px 5px;
  display: block;
  transition: all 0.3s ease;
}

.fpi_list_items ul li:hover,
.fpi_list_items ul li.active {
  border: 1px solid #1C69D4;
}

.modal-content img {
  width: 100%;
  height: 100%;
}

.fpi_list_items img {
  height: 35px;
  width: 55px;
  margin-right: 10px;
  object-fit: cover;
}

.col-md-8.adjustment {
  padding-right: 50px;
}

.footer-col ul li a:hover,
.rightitems ul li a:hover,
.footright ul li a:hover,
.social-links a i:hover,
.fpi_list_items ul li a:hover {
  color: #1C69D4;
}

.continue-btn button.btn-primary:hover {
  background-color: #1C69D4;
}

.perfectpopup {
  position: relative;
  /* padding-left: 356px; */
}

.perfpopimg {
  width: 356px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}

.perfpopimg img {
  width: 100%;
  height: 100%;
  display: block;
}

.perfpopright {
  padding: 30px 25px;
}

.radio {
  border-radius: 50%;
  padding: 5px;

}

.progress {
  margin-top: 25px;
}

.prog-range {
  margin: 25px 0px;
}

.tabsinside {
  display: flex;
  margin-top: 20px;
  align-items: center;
  padding-bottom: 20px;
}

.monthly-price,
.down-payment {
  display: block;
}

.left-area {
  padding-right: 20px;
}

span.monthly-price {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

small.down-payment {
  font-size: 10px;
  font-weight: 500;
  color: #000000;
}

.nav-pills>li>a {
  border-radius: 0px !important;
}

span.price {
  font-size: 36px;
  font-weight: 400;
  color: #000000;
}

.right-area sub {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.pfi_filled_tabs {
  border-radius: 36px;
  border: 0px none !important;
}

.pfi_filled_tabs .nav-item .nav-link {
  color: #000;
  background: #D9D9D9;
  font-size: 16px;
  font-weight: 400;
  border-radius: 0px;
}

.pfi_filled_white_tabs .nav-item .nav-link {
  background: #fff;
  border: 1px solid #000 !important;
}

.pfi_filled_tabs .nav-item:first-child .nav-link {
  border-radius: 36px 0px 0px 36px;
}

.pfi_filled_tabs .nav-item:last-child .nav-link {
  border-radius: 0px 36px 36px 0px;
}

.pfi_filled_tabs .nav-link.active {
  background: #000;
  border-color: #000 !important;
  color: #fff;
}

.pfi_cityState_box {
  width: 100%;
  padding: 20px;
  border-radius: 2px;
  border: 1.5px solid #E6E6E6;
}

.preQualifyContent {
  width: 100%;
}

.preQualifyPop .modal-dialog {
  max-width: 1080px !important;
}

/* .preQualifyPop  .modal-body{ background: url(../assets/images/pfi_pop_bg.png) no-repeat center center !important; background-size: cover !important; } */

.fpi_title_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.fpi_title_logo span {
  text-align: center;
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: .5px;
  font-weight: 700;
  color: #656565;
}

.fpi_title_logo i {
  width: 32px;
  display: block;
}

.fpi_title_logo img {
  display: block;
  width: 100%;
  height: auto;
}

.fpi_title_logo img.logo-setting {
  display: block;
  width: auto;
}

.preQualifyContent {
  max-width: 456px;
  margin: auto;
}

/* jam style for payment options */

.po_content {
  width: 100%;
  max-width: 700px;
}

.whiteShadow_range .MuiSlider-rail {
  border-radius: 8px;
  border: 1px solid #CFCFCF;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 18px;
}

.whiteShadow_range .MuiSlider-track {
  background: #BBD2F3;
  border-color: #BBD2F3;
  height: 18px;
}

.whiteShadow_range .MuiSlider-thumb {
  width: 30px;
  height: 30px;
  color: #0166B1;
}

.prequalifier_min_btn {
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
}

.prequalifier_min_btn strong {
  display: block;
  padding-right: 12px;
}

.prequalifier_min_btn small {
  display: block;
  font-weight: 300;
}

.prequalifier_calcIcon {
  padding: 6px;
  background: #000;
  margin-right: 10px;
  display: block;
}

.prequalifier_calcIcon svg {
  display: block;
  max-width: 16px;
  height: auto;
}

.po_tabs_cash {
  max-width: 400px;
}

.po_footer {
  width: calc(100% + 50px);
  background: #fff;
  margin: 0px -25px;
  border-top: 1px solid #CACACA;
  padding: 12px 15px;
  position: sticky;
  bottom: 0px;
  z-index: 333;
}

.pof_item {
  max-width: 140px;
}

.pof_item img {
  display: block;
  width: 100%;
  height: auto;
}

.po_content_height {
  width: 100%;
  min-height: calc(100vh - 160px);
}

.custom_toast_topCnter .toast {
  top: initial !important;
  bottom: 100% !important;
}

.tradeoptions_content {
  width: 100%;
}

.tradeOpt_vintabs {
  max-width: 534px;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #B8B8B8;
  min-height: 120px;
}

.tradeOpt_vintabs .nav-tabs {
  margin-top: -1px;
}

.tradeOpt_yourVehBox_title {
  border-radius: 22px;
  background: #D9D9D9;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.385px;
  padding: 0.5rem;
  text-align: center;
  margin-top: -1px;
}

.tradeOpt_yourVehBox_content span img {
  display: block;
  width: 100%;
  height: auto;
}

.tradeOpt_info_box {
  width: 100%;
  max-width: 635px;
  padding: 30px;
  border: 1px solid #ECECEC;
}

.tradeOpt_info_check {
  padding: 8px 0.75rem;
  border: 1px solid #ECECEC;
  display: flex;
  align-items: center;
  gap: 10px;
}

.pof_nextbtn a {
  display: block;
}

a.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.iad_cards_box {
  width: 100%;
  padding-right: 20px;
}

.iad_cards_img {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.iad_cards_img_link {
  display: block;
  width: 100%;
  height: 202px;
}

.iad_cards_img_link img {
  display: block;
  width: 100%;
  height: 100%;
  margin: auto;
  object-fit: cover;
  transition: all 0.3s ease;
}

.fullInfoPosList {
  width: 100%;
  padding: 10px;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

.fullInfoPosList ul li {
  padding-bottom: 14px;
}

.fullInfoPosList ul li a {
  display: flex;
  align-items: center;
  color: #fff;
}

.fullInfoPosList ul li a:hover {
  text-decoration: underline;
}

.fullInfoPosList ul li i {
  display: block;
  margin-right: 15px;
}

.iad_hov_menu:hover .iad_cards_img_link img {
  filter: blur(4px);
}

.iad_hov_menu:hover .fullInfoPosList {
  opacity: 1;
  visibility: visible;
}

.iad_card_add_btn {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  right: -20px;
  bottom: -20px;
  z-index: 3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  color: var(--color_blue);
}

.iad_card_add_btn:hover,
.iad_card_add_btn.active {
  background-color: var(--color_blue);
  color: #fff;
}

.mg_vehadon_left {
  position: sticky;
  top: 20px;
}

.dap_content .fp_date_box ul li {
  flex-grow: 1;
}

.dap_radio_box {
  opacity: 0.5;
}

.dap_radio_box.active {
  opacity: 1;
  border-color: #000000 !important;
}

.ins_dashedBox {
  border: 1px dashed #dee2e6;
  padding: 25px;
  text-align: center;
  position: relative;
}

.ins_dashedBox span {
  display: block;
  padding: 20px 0px;
  color: var(--color_grey2);
  font-size: 14px;
  line-height: 1.5em;
  letter-spacing: .5px;
  font-weight: 400;
}

.ins_dashedBox i {
  display: block;
}

.ins_dashedBox i svg {
  display: block;
  margin: auto;
  font-size: 24px;
  color: #221f1f;
}

.ins_uploadLicense_row {
  gap: 20px;
}

.btn_outlined_light {
  border-color: #B8B8B8;
  background-color: #F6F6F6;
  color: #221f1f;
}

.ins_fileInput {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  cursor: pointer;
  opacity: 0;
}

.ins_licenseImgBox {
  width: 100%;
  height: 200px;
  display: table;
  text-align: center;
}

.ins_licenseImgBox img {
  max-width: 100%;
  width: auto;
  max-height: 200px;
  height: auto;
}

.extInsurance_logos_row {
  margin: 0px -10px;
}

.extInsurance_logoBox {
  padding: 5px 10px;
}

.extInsurance_logoBox a {
  display: block;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.extInsurance_logoBox a img {
  display: block;
  max-height: 80px;
  margin: auto;
}

.extInsurance_logoBox a.active {
  border-color: #000000 !important;
}

.insu_mu_logos {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.insu_mu_bmw {
  width: 100px;
  height: 100px;
  border: 1px solid #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  position: relative;
  z-index: 1;
}

.insu_mu_bmw img {
  display: block;
  width: 100%;
  height: 100%;
  max-width: none;
}

.insu_mu_co {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.insu_mu_co img {
  display: block;
  width: 100%;
  height: 100%;
  max-width: none;
}

.insu_mu_mid {
  width: 237px;
  padding: 15px;
  text-align: center;
  border: 2px dotted #000;
  border-radius: 86px;
  position: relative;
}

.insu_mu_mid::after,
.insu_mu_mid::before {
  width: 30px;
  height: 100%;
  content: '';
  background: #fff;
  position: absolute;
  left: -16px;
  top: 0px;
  z-index: 0;
}

.insu_mu_mid::after {
  left: initial;
  right: -16px;
}