/* Maham Css */
.svh_modal_content .modal-header {
    padding: 0 !important;
    border-bottom: none !important;
}

/* shoping hub */

.mg_ep-wrapper {
    overflow-x: hidden;
    width: 100%;
}

.mg_auto-content {
    max-width: 1400px;
    margin: auto;
    width: 100%;
}

.mg_shoppinghub-wrapper {
    background-color: #fff;
    transition: all 0.3s ease;
}

.mg_shopinghubleft {
    position: relative;
    top: 12%;

}

.mg_headershopping-left a {
    background-color: #fff;
    padding: 15px;
    transition: all 0.3s ease;
}

.mg_shoppinghub-left a {
    background-color: #f6f6f6;
    padding: 15px;
    transition: all 0.3s ease;
}

.mg_shopinghub-ctr {
    background-color: #f6f6f6;
    transition: all 0.3s ease;
}

.mg_shopinghub-ctr .mg_img-ctr {
    width: 472px;
    height: 315px;
}

.mg_shopinghub-ctr .mg_img-ctr .mg_shopinghubleft {
    top: 0;
}

.mg_shopinghub-ctr .mg_img-ctr .mg_shopinghubleft .mg_ep-imgcontent {
    height: 315px;
    width: 100%;
    display: flex;
    vertical-align: middle;
    text-align: center;
}

.mg_shopinghub-ctr .mg_img-ctr .mg_shopinghubleft .mg_ep-imgcontent .mg_img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    display: block;
    margin: auto;
}

.mg_shoppinghub-text {
    color: black;
    font-size: 24px;
    text-decoration: underline;
}

.mg_shopinghubmiddle {
    margin-right: 123px;
}

.mg_shopinghubmiddle p {
    width: 268px;
}

.mg_shopinghubright ul li a {
    background-color: #fff;
    padding: 15px;
}

.mg_shopinghubright-content {
    padding: 30px;
}

.mg_toast-width {
    width: 320px;
}

.mg_homeshoping-toast {
    position: absolute;
    top: 105% !important;
    left: 4%;
    border-radius: 0;
    margin-left: -80px;
    background-color: #fff;
    z-index: 11;
}

.mg_homeshoping-toast2 {
    top: 105% !important;
    left: 33%;
    z-index: 11;
}

.mg_notifyme-banner {
    height: 60px;
}

.mg_carlist-img ul li img {
    width: 80px;
}

.mg_carlist-img ul li {
    padding: 8px;
}

.header_search_circle {
    width: 40px;
    height: 40px;
}

.mg_homeshoping-toast3 {
    top: 130% !important;
    left: 27%;
    z-index: 11;
    min-width: 265px;
    width: 265px;
}

.mg_homeshoping-toast3 img {
    position: relative;
    right: 17px;
    width: 265px;
    top: -16px;
    height: 129px;
}

.mg_toast3-icon {
    width: 15px !important;
}

.mg_toast3-text ul li {
    font-size: 12px;
}

.mg_shopinghub-hover {
    transition: all 0.3s ease;
}

.mg_shopinghub-hover:hover {
    background: #fff;
    box-shadow: 0 12px 34px -3px rgba(0, 0, 0, .16);
}

.mg_img-ctr {
    background-color: #f6f6f6;

}

.mg_toast-close {
    z-index: 111;
    background-color: transparent;
}

.mg_left-img {
    width: 100%;
    background-size: cover;
}

.mg_position-carimg {
    width: 100%;
}

.mg_position-carimg img {
    width: 100%;
    height: auto;
}

.mg_transport-list img {
    width: 100%;
    display: block;
    object-fit: cover;
    height: 100%;
}

.mg_transport-list {
    gap: 10px;
}

.mg_transport-list li {
    width: calc(33.33% - 8px);
}

.mg_vehiclelist-Content {
    font-size: 12px;
    font-weight: 500;
}

.mg_pill-btn {
    background: #fff;
    color: #1C69D4;
    font-weight: 500;
    padding: 4px 10px;
    border-radius: 50rem !important;
    font-size: 10px;
}

.mg_positionPill {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 6px;
    gap: 6px;
}

.mg_tabs {
    padding: 0px 70px;
}

.mg_bg-Content {
    padding-top: 36px;
    opacity: 0.7;
    font-weight: 600;
}

.mg_VehicleImg-position {
    display: block;
    height: 165px;
}

/* Ending page */
.mg_ending-btn {
    background-color: #fff;
    padding: 15px;
    border: #f6f6f6;
}

.mg_ending-btn:hover {
    background-color: #262626;
    color: #fff !important;
}

.mg_endingpage-header {
    background-color: #f6f6f6;
}

.mg_endingpage-ctr {
    background-color: #fff;
    padding-bottom: 40px;
}

.mg_endingpageleft-content {
    width: 400px;
}

.mg_endingpageleft-content h3 {
    color: #262626;
    font-family: Encode Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
}

.mg_endingpageleft-content p {
    color: #262626;
    font-family: Encode Sans;
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
}

.mg_endingpageright-inner {
    width: 887px;
}

.mg_img,
.mg_endingslider-box img {
    width: 100%;
    height: auto;
}

.mg_ep-content {
    margin-right: 52px;
}

.mg_whileuwait {
    background-color: #f6f6f6;
}

.mg_endingtabscontent ul li {
    padding: 0px 20px;
}

.mg_ending-tab {
    padding: 35px 20px;
    opacity: 0.7;
    border-bottom: solid 1px transparent;
    transition: all 0.3s ease;
}

.mg_ending-tab:hover {
    border-bottom: solid 1px black;
    opacity: 1;
}

.mg_height {
    height: 710px;
}

.mg_endingcarimg {
    width: 600px;
}

.mg_dist-details {
    padding-top: 65px;
}

.mg_btn:hover {
    color: white;
    background-color: black;
    border-color: var(--bs-btn-hover-border-color);
}

.mg_btn {
    background: white;
    color: black;
}

.mg_col6height a {
    height: 440px;
}

.mg_sliderposition {
    width: 100%;
    margin-left: 13%;
}

.mgeds_txt p {
    font-weight: 100;
}

.mg_endingslider-box {
    width: 360px;
}

/* Dark mode */

.mg_ep-wrapper-dark,
.mg_ep-wrapper-dark .mg_endingpage-header,
.mg_ep-wrapper-dark .mg_endingpage-ctr {
    background-color: #1A1A1A;
    transition: all 0.3s ease;
}

.mg_ep-wrapper-dark .header,
.mg_ep-wrapper-dark .mg_whileuwait,
.mg_ep-wrapper-dark .mg_endingtabscontent {
    background-color: #262626;
    border-bottom: none;
    transition: all 0.3s ease;
}

.mg_ep-wrapper-dark .header a,
.mg_ep-wrapper-dark p,
.mg_ep-wrapper-dark h3,
.mg_ep-wrapper-dark span,
.mg_ep-wrapper-dark strong,
.mg_ep-wrapper-dark a,
.mg_ep-wrapper-dark .react-horizontal-scrolling-menu--arrow-left,
.mg_ep-wrapper-dark .react-horizontal-scrolling-menu--arrow-right,
.mg_ep-wrapper-dark .mg_epdarktheme-txt {
    color: #fff;
}

.mg_ep-wrapper-dark .mg_ending-btn {
    background-color: #262626;
    border: #f6f6f6;
}

.mg_ep-wrapper-dark .mg_ending-tab:hover {
    border-bottom: solid 1px #ffffff;
    opacity: 1;
}

.mg_ep-wrapper-dark .mg_btn,
.mg_ep-wrapper-dark .mg_ep-themebtn a {
    background-color: #262626;
    color: #fff;
}

.mg_ep-wrapper-dark .mg_btn:hover,
.mg_ep-wrapper-dark .mg_ending-btn:hover {
    background-color: #fff;
    color: #262626 !important;
}

.mg_ep-wrapper-dark .iad_card_add_btn {
    color: var(--color_blue);
}

.mg_ep-wrapper-dark .iad_card_add_btn:hover,
.mg_ep-wrapper-dark .iad_card_add_btn.active {
    background-color: var(--color_blue);
    color: #fff;
}

.mg_ep-wrapper-dark .mg_img-dark {
    display: block;
}

.mg_ep-wrapper-dark .mg_img-light {
    display: none;
}

.mg_img-dark {
    display: none;
}

/* Responsiveness */
@media (min-width: 1280px) and (max-width: 1360px) {}

@media (min-width: 1024px) and (max-width: 1279px) {
    .mg_shoppinghub-wrapper .mg_shoppinghub-text {
        font-size: 20px;
    }

    .mg_shoppinghub-wrapper .content-3,
    .mg_shoppinghub-wrapper .label-2 {
        font-size: 12px;
    }

    .mg_shoppinghub-wrapper .headline-6 {
        font-size: 16px;
    }

    .mg_shoppinghub-wrapper .label-1 {
        font-size: 14px;
    }

    .mg_ep-imgcontent {
        width: 300px;
    }

    .mg_shopinghubmiddle {
        margin-right: 25px;
    }

    .mg_shopinghubright-content {
        padding: 20px;
    }

    .mg_shopinghubleft {
        position: relative;
        top: 22%;
    }

    .mg_homeshoping-toast2 {
        left: 45%;
        width: 255px;
    }

    .mg_col6height a {
        height: 305px;
    }

    .mg_height {
        height: 526px;
    }

    /* Models Header responive */
    .inventory_btn {
        top: -72px;
    }

    .hm_future_title strong,
    .hm_future_title a {
        font-size: 18px;
    }

    .hm_future_right {
        width: 27%;
    }

    .hm_models_heading h1 img {
        max-width: 52px;
    }

    .hm_models_heading h1 {
        font-size: 38px;
    }

    .hm_models_box b {
        font-size: 22px;
    }

}

@media (min-width: 768px) and (max-width: 1023px) {
    .mg_hs-flex {
        display: flex !important;
        flex-direction: column !important;
    }

    .mg_shopinghubmiddle {
        margin-right: auto;
    }

    .mg_shoppinghub-wrapper .content-3,
    .mg_shoppinghub-wrapper .label-2 {
        font-size: 12px;
    }

    .mg_shopinghubright div {
        padding-top: 0 !important;
        padding-bottom: 10px !important;
    }

    .shopinghub-footer {
        padding-top: 12px 0px !important;
    }

    .mg_sh-wrapper-content {
        padding: 0 !important;
    }

    .mg_homeshoping-toast .toast-body,
    .mg_homeshoping-toast2 .toast-body {
        padding-top: 12px !important;
    }

    .mg_shopinghubright ul li:last-child {
        margin-right: 0px !important
    }

    /* ending page */
    .mg_endingpage-ctr {
        padding-top: 35px;
    }

    .mg_endingpageleft-content h3 {
        font-size: 36px;
    }

    .mg_endingpageleft-content p {
        font-size: 14px;
    }

    .mg_endingpageleft-content {
        width: 300px;
    }

    .mg_highlight-centerimg {
        width: 480px;
    }

    .mg_ep-content {
        margin-right: 39px;
    }

    .mg_endingslider-box {
        width: 280px;
    }

    .mg_col6height a {
        height: 355px;
    }

    .mg_height {
        height: 442px;
    }

    .mg_col6height a {
        height: 202px;
    }

    /* Modal Saved Vehicle */

    .modal-dialog {
        max-width: 650px !important;
    }

    .svh_modal_content {
        padding: 15px !important;
    }

    .mg_modal-left-content ul li {
        padding: 2px;
    }

    .form-check-input {
        width: 20px;
        height: 20px
    }

    /* Modals header */

    .models_popup .nav-tabs .nav-link {
        font-size: 12px;
    }

    .hm_models_auto {
        padding: 120px 6.495% 0;
    }

    .inventory_btn {
        top: -70px;
    }

    .hm_models_heading h1 img {
        max-width: 45px;
    }

    .hm_models_heading h1 {
        font-size: 38px;
    }

    .hm_future_title strong,
    .hm_future_title a {
        font-size: 18px;
    }

    .hm_future_right {
        width: 36%;
    }

    .hm_models_box b {
        font-size: 18px;
    }

    .mg_shopinghub-ctr .mg_img-ctr {
        width: 100%;
        height: auto;
    }

    .mg_shopinghub-ctr .mg_img-ctr .mg_shopinghubleft .mg_ep-imgcontent {
        height: auto;
    }

    .mg_shopinghub-ctr .mg_img-ctr .mg_shopinghubleft .mg_ep-imgcontent .mg_img {
        max-height: inherit;
    }

    /* Modal Saved Vehicle */

    .models_popup .modal-dialog {
        max-width: 650px !important;
        margin: auto;
    }

    .svh_modal_content {
        padding: 15px !important;
    }

    .mg_modal-left-content ul li {
        padding: 2px;
    }

    .form-check-input {
        width: 20px;
        height: 20px
    }

    /* Modals header */

    .models_popup .nav-tabs .nav-link {
        font-size: 12px;
    }

    .hm_models_auto {
        padding: 120px 6.495% 0;
    }

    .inventory_btn {
        top: -70px;
    }

    .hm_models_heading h1 img {
        max-width: 45px;
    }

    .hm_models_heading h1 {
        font-size: 28px;
    }

    .hm_future_title strong,
    .hm_future_title a {
        font-size: 18px;
    }

    .hm_future_right {
        width: 36%;
    }

    .hm_models_box b {
        font-size: 18px;
    }

}

@media only screen and (max-width: 767px) {

    .mg_hs-flex,
    .mg_hs-header {
        display: flex !important;
        flex-direction: column !important;
    }

    .mg_hs-content,
    .mg_shfooter-content {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .mg_shopinghubmiddle {
        margin-right: 0;
    }

    .mg_auto-content {
        max-width: 410px;
        margin: auto;
    }

    .mg_shopinghubmiddle .filtersH_link svg {
        display: block !important;
    }

    .mg_shopinghubmiddle div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .mg_shopinghubmiddle div p {
        text-align: center;
    }

    .mg_shoppinghub-wrapper .content-3,
    .mg_shoppinghub-wrapper .label-2 {
        font-size: 12px;
    }

    .mg_shopinghubright div {
        padding-top: 0;
        padding-bottom: 10px !important;
    }

    .shopinghub-footer {
        padding: 12px 0px !important;
    }

    .mg_headershopping-left a {
        padding: 18px 7px;
    }

    .mg_hs-content {
        padding: 22px;
    }

    .mg_shoppinghub-left a {

        padding: 16px 8px;
    }

    .label-1 {
        font-size: 13px;
    }

    .mg_homeshoping-toast {
        left: 15%;
    }

    .mg_homeshoping-toast,
    .mg_homeshoping-toast2 {
        padding-top: 12px !important;
    }

    .mg_shopinghubright-content {
        padding: 15px;
    }

    .mg_shopinghubright-content .mg_hs-content {
        padding: 0px;
    }

    .inventory_result_content_inner {
        padding: 15px;
    }

    .mg_shoppinghub-left ul {
        display: block !important;
        width: 100%;
    }

    .mg_shoppinghub-left ul li {
        margin: 8px 0px !important;
    }

    .mg_svm-leftcontent {
        display: none;
    }

    .modal-dialog {
        margin: 1.5rem !important;
    }

    .hm_models_heading {
        flex-direction: column;
        align-items: center;
    }

    .hm_models_auto {
        padding: 120px 3.495% 0;
    }

    .models_popup .nav-tabs .nav-link {
        font-size: 12px;
    }

    .inventory_btn {
        top: -80px;
        left: 0;
        right: auto;
    }

    .hm_models_heading h1 img {
        max-width: 42px;
    }

    .hm_col3 .hm_models_box b {
        font-size: 12px;
    }

    .hm_future_title strong,
    .hm_future_title a {
        font-size: 16px;
    }

    .hm_future_left {
        width: 45%;
        position: relative;
    }

    .hm_future_right {
        width: 40%;
    }

    .hm_models_box b {
        font-size: 16px;
    }

    /* end 767 */
}

@media (min-width: 0px) and (max-width: 450px) {

    .mg_headershopping-left ul li {
        margin-right: 10px !important;
    }

    .mg_headershopping-left ul li:last-child {
        margin-right: 0px !important;
    }

    .mg_headershopping-left ul li .filtersH_link i {
        display: none;
    }

    .mg_headershopping-left ul li:last-child .filtersH_link i {
        display: block;
    }

    .mg_homeshoping-toast {
        left: 25%;
    }

    .mg_shopinghubright div {
        padding-bottom: 5px !important;
    }

    .mg_homeshoping-toast2 {
        left: 25%;
    }

    .label-1 {
        font-size: 12px;
    }

    /* ending page */
    .mg_endingpageleft-content {
        width: 256px;
        text-align: center;
        padding-top: 40px;
    }

    .mg_endingpageright-content {
        padding-top: 10px;
    }

    .mg_ending-btn {
        padding: 12px;
    }

    .mg_ep-wrapper-dark .mg_img-dark {
        margin-right: -11px;
    }

    .mg_whileuwait-content {
        flex-direction: column;
        padding: 10px 0px !important;
    }

    .mg_whileuwait-content ul li {
        padding: 10px;
    }

    .mg_whileuwait-content button {
        display: block;
        width: 60%;
    }

    .mg_endingpageleft-content h3 {
        font-size: 30px;
    }

    .mg_endingpageleft-content p {
        font-size: 14px;
    }

    .mg_flex-reverse {
        display: flex;
        flex-direction: column-reverse;
    }

    .mg_endingtabscontent ul li {
        padding: 0px 10px;
    }

    .mg_highlight-centerimg .mg_img-light {
        width: 285px;
    }

    .mg_highlight-centerimg .mg_img-dark {
        width: 350px;
        margin-left: -43px;
    }

    .mg_ep-content {
        margin-right: 0px;
    }

    .mg_endingslider-box {
        width: 125px;
    }

    .mg_sride-ctr span,
    .mg_sride-ctr p {
        font-size: 12px;
    }

    .mg_sride-ctr ul {
        flex-wrap: wrap;
    }

    .mg_col6height a {
        height: 202px !important;
    }

    .mg_height {
        height: 202px;
    }

    .mg_dist-details p {
        font-size: 12px;
        padding-top: 10px;
    }

    .mg_ep-themebtn svg {
        display: block !important;
    }

    .modal-dialog {
        margin: 0.6rem !important;
    }

    .form-check-input {
        width: 20px;
        height: 20px;
    }
}