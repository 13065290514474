.saved_view{ text-align: center;}


.saved_view li a, .saved_view li button{ font-size: 13px !important;}

.saved_view li svg{ font-size: 16px !important;}

.arrive_soon{ width: 100%; padding: 10px 0px 20px 0px; }


.price_heading_box h2 small{ font-size: 16px; line-height: 20px; letter-spacing: .25px; font-weight: 400; }

.price_block{ width: 100%; border: 1px solid #cacaca; border-radius: 3px; }

.price_heading_row{ width: 100%; display: flex; justify-content: space-between; padding-bottom: 20px; }

.price_heading_box{ width: auto; }

.verticle_line{ background-color: #cacaca; flex-shrink: 0; margin: 0px 30px; width: 1px; }


.price_sec{ width: 100%; padding: 20px; }

.incentives{ text-align: center;  }

.leasingTabs{ width: 100%; background-color: #f6f6f6; padding: 20px; border-top: 1px solid #cacaca; border-radius: 0px 0px 3px 3px; }

.leaseForm{ width: 100%;}


.moreWays_main{ width: 100%; padding-top: 30px; padding-bottom: 30px; }

.moreways_accordian_box{ width: 100%; background-color: #f6f6f6;  padding: 20px; border: 1px solid #cacaca;  border-radius: 0px 0px 3px 3px; }

.start_checkout_sec{ width: 100%; padding: 15px; background: #FFFFFF; position: -webkit-sticky;  position: sticky; bottom: 0px; }

.checkout_title{ width: 100%; padding-bottom: 10px;}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .verticle_line{ margin: 0 10px; }
}

@media (max-width: 767.98px) {
    .verticle_line{ margin: 0 15px; }
    .price_heading_box h2{ font-size: 14px; }
    .price_heading_box h2 small{ font-size: 13px; }
    

}

















