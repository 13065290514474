
.product_item_box{ padding: 20px; width: 100%; transition: all 0.3s ease; }
.product_item_box:hover{ background-color: #fff; -webkit-box-shadow: 0 12px 34px -3px rgba(0,0,0,.16);  box-shadow: 0 12px 34px -3px rgba(0,0,0,.16);}

.product_item_inner{ width: 100%;}

.gridItem_imgBox{ width: 100%; background-color: #fff; margin-bottom: 20px; position: relative;  margin-bottom: 20px; transition: all 0.3s ease; }

.product_item_box:hover .gridItem_imgBox{ background-color: #F5F5F5; }

.gridItem_img{  width: 100%; height: 180px; display: flex; align-items: center; justify-content: center; }

.gridItem_img img{ display: block; max-width: 60%; max-height: 100%; }

.fvrt_btn{ width: 30px; height: 30px; border-radius: 50%; display: block; cursor: pointer;  display: flex; align-items: center; justify-content: center; color: #000; transition: all 0.3s ease;  }

.fvrt_btn:hover{ color:#1C69D4 ;}

.fvrt_btn.active{ color: #1C69D4;}

.msrp small{ display: flex; align-items: center; justify-content: center; color: #333; font-size: 10px; font-weight: 300; letter-spacing: 2.2px; line-height: 1.4em; padding-bottom: 4px; }

.msrp b{ color: #333;  text-align: center;  font-size: 14px; font-weight: 300; line-height: 1.4em; letter-spacing: 0.42px; }

.dealership{  display: flex; align-items: center; justify-content: center; color: #333; font-size: 10px; font-weight: 500; }

.area_loc{ width: 100%; padding-bottom: 15px; margin-bottom: 18px; border-bottom: 1px solid #E6E6E6; }

.area_loc h3{  color: #484848;  font-size: 12px; font-weight: 600; letter-spacing: -0.3px; line-height: 1.5em;  }

.gridItem_switch{  background: #D9D9D9; width: 40px; height: 20px; position: absolute; right: 7px; top: 7px; display: flex; align-items: center; justify-content: center; cursor: pointer;  transition: all 0.3s ease; padding-bottom: 2px;  }

.gridItem_switch i{ width: 50%; opacity: 0.5; transition: all 0.3s ease; }
.gridItem_switch i svg{ display: block;  max-height: 12px; max-width: 15px; margin: auto; }

.gridItem_switch i:first-child{ opacity: 1; }
.gridItem_switch.active i:last-child{ opacity: 1; }
.gridItem_switch.active i:first-child{ opacity: 0.5; }

.gridItem_switch:after{ content: ""; width: 50%; position: absolute; left: 0px; bottom: 0px; background-color: #0066B1; height: 2px;  transition: all 0.3s ease;  }

.gridItem_switch.active:after{ left: 50%; }

.gridItem_imgInner{ width: 100%; height: 100%; object-fit: cover;}
