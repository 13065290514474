* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

ol,
ul {
    margin: 0px;
    padding: 0px;
}

button {
    outline: none;
    background: none;
    border: 0px none;
    border-radius: 0px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
}

:root {
    --header_height: 90px;
    --color_greydark: #221f1f;
    --color_grey: #4c4c4c;
    --color_grey2: #3E3E3E;
    --color_light_grey: #8E8E8E;
    --color_black: #000;
    --color_white: #fff;
    --color_blue: #1c69d3;
    --color_light_grey2: #ebebeb;
    --border_color: #cacaca;
    --btn_dark: #221f1f;
    --btn_secondary: #4D4D4D;
    --btn_primary: #1C69D4;
    --bs-primary: #1C69D4
}

body {
    font-family: 'Encode Sans', sans-serif !important;
    color: #000;
    font-size: 14px;
    background-color: #F5F5F5;
}

.header_logo {
    width: 100px;
    flex-shrink: 0;
}

.header_logo a {
    display: block;
}

.header_logo img {
    display: block;
    max-width: none;
    width: 100%;
    height: auto;
}

.header {
    width: 100%;
    border-bottom: 1px solid var(--border_color);
    background-color: var(--color_white);
}

.header a {
    display: block;
    font-size: 16px;
    line-height: 1.4em;
    letter-spacing: .25px;
    color: var(--color_grey);
    transition: all .3s ease-in-out;
    font-weight: 700;
}

.header a:hover {
    color: var(--color_blue);
}

.header_inner {
    width: 100%;
    padding: 20px 30px;
    min-height: var(--header_height);
}

.header_menu ul li {
    margin-left: 30px;
}

.header_menu ul li>a {
    position: relative;
    display: flex;
    align-items: center;
}

.header_menu ul li.has_dropdown>a {
    padding-right: 26px;
}

.header_menu ul li.has_dropdown>a .material-icons {
    position: absolute;
    right: 0px;
}

.header_menu ul li:hover>a,
.header_menu ul li.active>a {
    color: var(--color_blue);
}

.header_location {
    display: block;
    font-size: 13px;
    line-height: 1.4em;
    letter-spacing: .25px;
    color: var(--color_grey);
    transition: all .3s ease-in-out;
    font-weight: 700;
    position: relative;
}

.header a.header_location {
    font-size: 13px;
}

.header_location svg {
    font-size: 1rem !important;
}

.header_search_circle {
    background-color: var(--color_light_grey2);
    margin: 0;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 14px !important;
}

.header_search_circle svg {
    font-size: 1.2rem !important;
}

.product_details_header {

    position: sticky;
    top: 0;
    z-index: 999;
}

.filtersHeader {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 80;
    padding: 0 30px;
    width: 100%;
    background-color: var(--color_white);
    border-bottom: 1px solid var(--border_color);
    font-size: 13px;
    line-height: 20px;
    letter-spacing: .5px;
    display: flex;
    align-items: center;
    min-height: 60px;
}

.showHideFilterButton {
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0;
    color: var(--color_grey);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: .5px;
    gap: 10px;
    font-weight: 700;
    transition: all .3s ease-in-out;
}

.showHideFilterButton:hover {
    color: var(--color_blue);
}

.filtersHeader_inner {
    width: 100%;
}

.filtersHeader_left {
    width: 300px;
    flex-shrink: 0;
}

.filtersHeader_right {
    width: 100%;
}

.filtersHeader_right ul>li {
    margin-left: 30px;
}

.filtersH_link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    font-size: 11px;
    line-height: 1.4em;
    letter-spacing: .80px;
    color: var(--color_grey);
    transition: all .3s ease-in-out;
    font-weight: 700;
}

.filtersH_link:hover {
    color: var(--color_blue);
}

.filtersH_link svg {
    font-size: 1.2rem !important;
}

.filtersort_text_dropdown .dropdown-toggle {
    border: 0px none !important;
}

.filtersort_text_dropdown .dropdown-toggle::after {
    display: none !important;
}

.filtersort_text_dropdown.show .filtersH_link {
    color: var(--color_blue);
}

.filtersort_text_dropdown .dropdown-item {
    font-size: 11px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: .25px;
    line-height: 1.4em;
    color: var(--color_grey);
}

.number_badge {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: .25px;
    border-radius: 20px;
    background-color: var(--color_blue);
    color: white;
    min-width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter_drawer_wrapper {
    width: 100%;
    display: flex;
}

.filter_drawer {
    flex-shrink: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    height: calc(100vh - 66px);
    z-index: 1;
    -ms-flex-item-align: start;
    align-self: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    background-color: #fff;
    width: 300px;
}

.dealerShip_head {
    padding: 20px;
}

.accordion-button {
    color: var(--color_grey);
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.26px;
    padding: 16px 20px;
}

.accordion-button,
.accordion-item {
    border-radius: 0px !important;
}

.accordion-item {
    border-left: 0px none;
    border-right: 0px none;
}

.accordion-button:focus,
.form-select:focus {
    box-shadow: 0px 0px 0px transparent !important;
}

.accordion-button:hover {
    color: var(--color_blue);
}

.accordion-button::after {
    background: url(../assets/images/acc_plus.svg) no-repeat center center !important;
}

.accordion-button:not(.collapsed)::after {
    background: url(../assets/images/acc_minus.svg) no-repeat center center !important;
}

.accordion-button:not(.collapsed) {
    color: var(--color_grey);
    background-color: #fff;
    box-shadow: 0px 0px 0px transparent !important;
}

.accordion-body {
    padding: 10px 20px 16px 20px;
}

.acc_title {
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 2px;
    display: block;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.fs_model_series_grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(auto, 1fr));
    grid-gap: 10px;
    margin-bottom: 15px;
}

.fs_model_series_grid.fs_grid_5 {
    grid-template-columns: repeat(5, minmax(52px, 1fr));
    grid-gap: 10px;
    margin-bottom: 15px;
}

.fs_model_seriesselect {
    cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    width: auto;
    height: 54px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;

    color: var(--color_grey);
    border: 1px solid var(--border_color);
    border-radius: 2px;
    padding-top: 2px;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 15px;
    margin-bottom: 5px;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: .5px;
    font-weight: 700;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

.fs_model_seriesselect:hover {
    color: #1c69d3;
    border: 1px solid #1c69d3;
}

.fs_model_seriesselect.active {
    border: 2px solid #1c69d3;
}

.acc_top_border {
    padding-top: 20px;
    border-top: 1px solid #ebebeb;
}

.form-select {
    border-radius: 0px !important;
    border: 1px solid var(--border_color);
    font-size: 13px;
    line-height: 16px;
    letter-spacing: .25px;
    font-weight: 700;
    color: var(--color_grey);
    padding: 12px 20px;
}

.fs_bodystyle {
    width: 100%;
}

.fs_bodystyle_label {
    width: 100%;
    border: 1px solid #f2f2f2;
    height: 54px;
    margin-top: 10px;
    padding: 0 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    letter-spacing: .5px;
    font-weight: 700;
    color: var(--color_grey);
    gap: 10px;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

.fs_bodystyle_label:hover {
    color: #1c69d3;
    border: 1px solid #1c69d3;
}

.fs_bodystyle_label.active {
    border: 2px solid #1c69d3;
}

.fs_bodystyle_img {
    display: block;
    max-width: 40px;
}

.fs_bodystyle_img img {
    display: block;
    max-width: 100%;
}

.label-3--bold {
    font-size: 11px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: .25px;
    color: var(--color_grey);
}

/* .nav.nav-tabs{ display: inline-flex;} */

.nav-tabs .nav-link {
    color: var(--color_light_grey);
    text-align: center;
    font-size: 11px;
    font-weight: 700;
    border: 0px none;
    border-bottom: 2px solid transparent !important;
    letter-spacing: 0.385px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: var(--color_black);
    border-bottom-color: var(--color_blue) !important;
}

.inventory_result_content {
    width: 100%;
    transition: all 0.3s ease-in-out;
}

.inventory_result_content_inner {
    padding: 40px 25px;
}

.content-2--bold {
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: .5px;
    font-weight: 700;
    color: var(--color_black);
}

.content-2--regular-grey {
    color: var(--color_grey2);
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: .5px;
    font-weight: 400;
}

.content-3--regular-grey {
    color: var(--color_grey2);
    font-size: 14px;
    line-height: 1.5em;
    letter-spacing: .5px;
    font-weight: 400;
}

.content-3 {
    color: var(--color_grey2);
    font-size: 14px;
    line-height: 1.5em;
    letter-spacing: .5px;
    font-weight: 400;
}

.black {
    color: var(--color_black) !important;
}

.bold {
    font-weight: 700 !important;
}

.regular {
    font-weight: 400 !important;
}

.semiBold {
    font-weight: 600 !important;
}

.light {
    font-weight: 300 !important;
}

.capitalize {
    text-transform: capitalize !important;
}

.uppercase {
    text-transform: uppercase !important;
}

.headline-5 {
    font-size: 28px;
    letter-spacing: -.1px;
    line-height: 1.4em;
    color: var(--color_greydark);
}

.headline-7 {
    font-size: 24px;
    letter-spacing: -.1px;
    line-height: 1.4em;
    color: var(--color_greydark);
}

.headline-6 {
    font-size: 20px;
    line-height: 1.4em;
    letter-spacing: -.1px;
}

.headline-4 {
    font-size: 38px;
    letter-spacing: -.1px;
    line-height: 1.2em;
    color: var(--color_greydark);
}

.label-1,
.label-1 .accordion-button {
    font-size: 16px;
    letter-spacing: .25px;
    line-height: 1.4em;
    color: var(--color_greydark);
}

.label-2 {
    font-size: 13px;
    letter-spacing: .25px;
    line-height: 1.4em;
    color: var(--color_greydark);
}

.color_blue {
    color: var(--color_blue) !important;
}

.btn {
    font-size: 12px;
    font-weight: 700;
    border-radius: 2px !important;
    padding: 10px 25px;
}

.btn .MuiSvgIcon-root {
    font-size: 140%;
}

.btnbtn-dark {
    background-color: var(--btn_dark);
}

.btn-secondary {
    background: var(--btn_secondary);
}

.btn-primary {
    background: var(--btn_primary);
}

.blue_hover:hover {
    color: var(--btn_primary) !important;
}

.outline-secondary {
    background-color: #fff;
    color: var(--btn_secondary);
}

.vehicles_list_items {
    width: 100%;
}

.vehicles_list_ul {
    width: 100%;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.vehicles_list_ul>li {
    width: 33.33%;
}

.text_elipsis {
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}

.help_icon {
    width: 16px;
    height: 16px;
    display: block;
    background: url(../assets/images/help_icon.svg) no-repeat center center;
    background-size: 100% auto;
}

.tooltip_box {
    border-radius: 3px;
    z-index: 99999999999999999;
    text-align: left;
    width: 283px;
    padding: 20px;
    box-shadow: 0 4px 20px 4px rgba(0, 20, 60, .05), 0 4px 80px -8px rgba(0, 20, 60, .08);
    background: #fff;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: .5px;
    color: #717171;
    margin-top: 10px !important;
    margin-bottom: 6px;
}

/* .tooltip_box_top:before{ content: '';  border-bottom: 8px solid #fff;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    top: -7px;
     position: absolute;
    width: 0;
    transform: translateX(-52%);
    height: 0; left: 50%;  } */

.form-control {
    border-radius: 0px !important;
    border-color: #ECECEC;
}

.error {
    color: red;
}

.form-control:focus {
    box-shadow: 0px 0px 0px transparent !important;
}

.has_checkbox_row {
    display: block;
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    max-width: 100%;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .25px;
    line-height: 1.4em;
}

.has_coloricon {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.coloricon {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin-right: 10px;
    display: block;
    background-color: #000;
}

.coloricon-black {
    background-color: #000;
}

.coloricon-blue {
    background-color: #1a72b7;
}

.coloricon-brown {
    background-color: #852b01;
}

.coloricon-gray {
    background-color: #989898;
}

.coloricon-green {
    background-color: #3b9e06;
}

.coloricon-orange {
    background-color: #ff4d00;
}

.coloricon-purple {
    background-color: purple;
}

.coloricon-white {
    background-color: #fff;
    border: 1px solid #ebebeb;
}

.coloricon-ivory {
    background-color: #f6f6ef;
}

.coloricon-red {
    background-color: #ff0038;
}

.coloricon-silver {
    background-color: #dedede;
}

.coloricon-beige {
    background-color: #ddd2bd;
}

.coloricon-yellow {
    background-color: #f4d100;
}

.coloricon-multy {
    background: conic-gradient(from 90deg, #e02020, #fa6400, #f7b500, #6dd400, #0091ff, #6236ff, #b620e0);
}

.form-check-input {
    width: 24px;
    height: 24px;
    border: 1px solid #bbb;
    border-radius: 2px;
    box-shadow: 0px 0px 0px transparent !important;
    margin: 0px;
}

.filter_options_divider {
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    margin: 25px 0;
}

.has_coloricon.text_elipsis {
    width: calc(100% - 24px);
    display: block;
}

.filter_drawer::-webkit-scrollbar {
    display: none;
}

.has_status_icon {
    position: relative;
    margin-top: 2px;
}

.status_tag {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    right: 2px;
    top: -2px;
    display: block;
    background: #3DB014;
    border: 1px solid #fff;
}

.product_detail_grid_row {
    width: 100%;
    display: flex;
}

.product_detail_grid_left {
    width: 70%;
}

.product_detail_grid_right {
    width: 30%;
    position: relative;
    background-color: var(--color_white);
    padding: 25px;
}

.cpo-section-header {
    padding-bottom: 15px;
    margin-bottom: 25px;
    border-bottom: 1px solid var(--border_color);
}

.summary_accordian .accordion-button,
.summary_accordian .accordion-item {
    background-color: transparent !important;
}

.text_arrow_btn {
    display: inline-flex;
    align-items: center;
    color: var(--color_grey);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: .25px;
    font-weight: 700;
    gap: 2px;
    transition: all 0.3s ease;
}

.text_arrow_btn svg {
    color: var(--color_blue);
    margin-top: 2px;
}

.text_arrow_btn:hover {
    color: var(--color_blue);
}

.product_list_btn {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: .5px;
}

.order_status_text {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: .25px;
    color: var(--color_grey);
    font-weight: 700;
}

.order_status_icon {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    display: block;
    background: #3DB014;
    border: 1px solid #fff;
    margin-right: 6px;
}

.blue_color {
    color: var(--color_blue);
}

.underline {
    text-decoration-line: underline;
}

.blue_hov {
    transition: all 0.3s ease;
}

.blue_hov:hover {
    text-decoration: none;
    color: var(--color_blue);
}

.underline_text {
    color: #4D4D4D;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.36px;
    text-decoration-line: underline;
    transition: all 0.3s ease;
}

.underline_text:hover {
    text-decoration: none;
    color: var(--color_blue);
}

.eyebrow-2 {
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 2px;
    color: #221f1f;
    text-transform: uppercase;
}

.transparent_bold_tabs .nav-link {
    background-color: transparent !important;
    font-size: 14px;
}

.transparent_acc .accordion-button,
.transparent_acc .accordion-item {
    background-color: transparent !important;
}

.transparent_acc .accordion-item:first-child {
    border-top: 0px none;
}

.transparent_acc .accordion-item:last-child {
    border-bottom: 0px none;
}

.accordian_pad0 .accordion-button,
.accordian_pad0 .accordion-body {
    padding-right: 0px;
    padding-left: 0px;
}

.color_black {
    color: var(--color_black);
}

.checkout_btn {
    display: block;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 50px;
    gap: 8px;
    font-weight: 700;
}

.checkout_btn_help {
    width: 44px;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: #0653B6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.testdrive_btn i svg path {
    transition: all 0.3s ease;
}

.testdrive_btn:hover i svg path {
    fill: #fff;
}

.btn-outline-secondary {
    background-color: #fff;
    border-color: #ECECEC;
}

/* javed */

.cross_label_main {
    width: 100%;
    padding: 15px 15px 15px 15px;
    border-bottom: 1px solid #ebebeb;
}

.cross_label {
    width: 100%;
    padding-bottom: 10px;
}

.cross_label ul {
    display: flex;
    padding: 0;
    flex-wrap: wrap;
}

.cross_label ul li {
    padding-right: 5px;
    padding-bottom: 6px;
}

.cross_label ul li label {
    font-size: 12px;
    line-height: 1.3em;
    font-weight: 500;
    color: #fff;
    padding: 6px 35px 6px 10px;
    display: block;

    background-color: #000;
    cursor: pointer;
    position: relative;
    border-radius: 25px;
    transition: all 0.3s ease;
}

.cross_label ul li label i {
    width: 15px;
    height: 15px;
    display: block;
    border-radius: 50%;
    position: absolute;
    right: 10px;
    top: 50%;

    transform: translate(0, -50%);
    background-color: var(--color_grey);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.cross_label ul li label i svg {
    max-width: 13px;
}

.cross_label ul li label i svg:focus {
    outline: none;
}

.cross_label ul li label:hover i {
    background-color: #fff;
}

.cross_label ul li label:hover i svg path {
    fill: #000;
}

.cross_label_clear {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.cross_label_clear a {
    font-size: 14px;
    line-height: 1.3em;
    font-weight: 500;
    color: var(--color_grey);
    display: block;
    text-decoration: none;
    transition: all 0.3s ease;
}

.cross_label_clear a:hover {
    color: var(--btn_primary);
}

.text_arrow_btn:hover {
    color: var(--color_blue);

}

.bg_gallery_row {
    width: 100%;
}

.bg_gallery_box {
    display: block;
    cursor: pointer;
    height: 70px;
    position: relative;
    transition: all 0.3s ease;
}

.bg_gallery_box img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: none;
    object-fit: cover;
}

.bg_gallery_box:hover {
    box-shadow: 0px 0px 0px 1.5px var(--color_grey2);
    z-index: 2;
}

.bg_gallery_upload {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: var(--color_blue);
}

.bg_gallery_upload p {
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: .25px;
    color: var(--color_grey);
}

.bg_gallery_upload input {
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0;
    position: absolute;
    left: 0px;
    top: 0px;
    cursor: pointer;
}

.footer_pricing_auto {
    width: 80%;
    margin: auto;
}

.footer_pricing_sec .headline-5,
.footer_pricing_sec .eyebrow-2 {
    color: var(--color_white);
}

.footer_pricing_btn .btn {

    background: var(--btn_primary);
    padding: 13px 36px;
    font-size: 15px;
    color: #FFF;
    font-weight: 700;
    letter-spacing: 0.075px;
    border-radius: 0;
    margin-left: 20px;
}

.bg_gallery_row {
    width: 100%;
}

.bg_gallery_box {
    display: block;
    cursor: pointer;
    height: 70px;
    position: relative;
    transition: all 0.3s ease;
}

.bg_gallery_box img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: none;
    object-fit: cover;
}

.bg_gallery_box:hover {
    box-shadow: 0px 0px 0px 1.5px var(--color_grey2);
    z-index: 2;
}

.bg_gallery_upload {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: var(--color_blue);
}

.bg_gallery_upload p {
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: .25px;
    color: var(--color_grey);
}

.bg_gallery_upload input {
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0;
    position: absolute;
    left: 0px;
    top: 0px;
    cursor: pointer;
}

.bg_gallery_chooser {
    max-width: 376px !important;
    margin-left: 10px !important;
}

.mob_filter_overlay,
.filter_close_mob {
    display: none;
}

.bg-primary {
    background-color: var(--bs-primary) !important;
}

.alert-msg::after {
    border-left-color: var(--bs-primary) !important;
}