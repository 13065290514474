/* Waleed Css */

.digital_checkout_wrapper_grid_row {
    width: 100%;
    display: flex;
}


.checkout_sidenav {
    top: 0px;
    position: sticky;
    height: calc(100vh - 0px);
    z-index: 1;
    align-self: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    background-color: #F6F6F6;
    width: 300px;
}

.checkout_sidenav .header {
    background: none;
}

.search_vin_section {
    padding: 25px 32px 25px;
    border-bottom: 1px solid #ebebeb;
}

.search_input_container .form-control {
    width: 100%;
    height: 55px;
    padding: 5px 40px;
}

.search_input_container {
    position: relative;
    display: flex;
    align-items: center;
}

.search_vin_icon {
    cursor: pointer;
    position: absolute;
    left: 4px;
    padding: 0 5px;
    top: 15px;
}

.modal-content {
    border-radius: 0;
}

.modal-body {
    padding: 30px;
}

.paddingBottom {
    padding-bottom: 0 !important;
}


#v360-menu-btns {
    display: none !important;
}

.checkout_logo {
    display: block;
    padding-left: 10px;
}

.checkout_logo small {
    margin-bottom: 0;
}

.welcome_guest {
    padding: 25px;
    border-bottom: 1px solid #cacaca;
}

.signin_text {
    color: #000;
    transition: all 0.3s ease;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.36px;
    text-decoration-line: underline;
}

.signin_text:hover {
    text-decoration: none;
    color: var(--color_blue);
}

.checkout_logo h2.acc_title {
    margin-bottom: 5px !important;
}

.checkout_menu ul {
    padding: 35px 0;

}


.checkout_menu ul svg {
    margin-right: 10px;
}

.checkout_menu ul li {
    cursor: pointer;
    color: #666;
    text-align: center;
    width: 100%;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.78px;
    border-bottom: 1px solid #F6F6F6;
}

.checkout_menu ul li:hover {
    background-color: #fff;
    color: #000;
}

.checkout_menu ul li .header_location {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 35px;
    height: 49px;
}

.chatBoxSec ul {
    background-color: #fff;
    border: 1px solid #CACACA;
}

.chatBoxSec ul li {
    color: #000;
    font-style: normal;
    font-weight: 700;

    padding: 15px 40px;
    border-bottom: 1px solid #CACACA;
}

.chatBoxSec ul li:last-child {
    border-bottom: 0;
}

.chatBoxSec ul img {
    margin-right: 10px;
}

.backButton {
    margin-top: 10px;
}

.checkout_rightContent {
    position: relative;
    padding: 25px;
    width: calc(100% - 300px);
    background: #fff;
    font-weight: 300;
}

.checkout_rightContent.has_image {
    background-color: #000;
    background-size: cover;
    background-repeat: no-repeat;
    color: #FFF;

}

.main_content_sec {
    width: 100%;
    margin: 0 auto;
    padding-top: 5%;
    max-width: 1200px;
}

.leave_button button {
    border-color: #a9a9a9;
    background: transparent;
    color: #fff;
    font-size: 14px;
    border-radius: 0;
    padding: 7px 20px;
}


.leave_button button svg {
    width: 17px;
    margin-right: 10px;
}

.leftSection {
    border-right: 1px solid #fff;
}


.main_content_sec .leftSection h2 {
    color: #FFF;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 1.08px;
}

.main_content_sec .leftSection h1 {
    font-size: 64px;
    letter-spacing: -1.6px;
}

.digital_text {
    font-size: 22px;
    letter-spacing: -0.5px;
    margin-left: 10px;
}

.rightSection {
    padding-left: 5%;
}

.color_white {
    color: #fff;
}

.rightSection .justify-content-start {
    justify-content: flex-start !important;
    margin-bottom: 15px;
}

.footer_pricing_sec {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
}

.footer_pricing_auto {
    width: 80%;
    margin: auto;
}

.footer_pricing_sec .headline-5,
.footer_pricing_sec .eyebrow-2 {
    color: #fff;
}


.footer_pricing_btn .btn {

    background: #1C69D4;
    padding: 13px 36px;
    font-size: 15px;
    color: #FFF;
    font-weight: 700;
    letter-spacing: 0.075px;
    border-radius: 0;
    margin-left: 20px;
}

.help_icon_white {
    background: none;
}

.help_icon_white svg {
    max-width: 16px;
    max-height: 16px;
}

.modal-xxl {
    max-width: 100%;
    margin: 0;
}

.modelTabs {
    position: relative;
    padding-bottom: 20px;
    margin: 42px 4.165% 52px;
    border-bottom: 1px solid #cacaca;
}


.fade.toast.show {
    position: absolute;
    top: 59px;
    border-radius: 0;
    margin-left: -80px;
    background-color: #fff;
}

.toast-header {
    border: 0;
    position: absolute;
    right: 0;
}

.fade.toast.show button.btn-close {
    font-size: 10px;
}

.toast-body {
    padding: 0;
}

.dealerSubMenu p {
    font-size: 12px;
}

.dealerSubMenu .small {
    font-size: 11px;
}

.dealerSubMenu .has_backgroundcolor {
    background-color: #e3e3e3;
}

.has_backgroundcolor .acc_title.small {
    font-size: 10px;
}

.submenuinner_icons .filtersH_link svg {
    font-size: 0.8rem !important;
}

.mainHomePage .header {
    background-color: transparent;
}

.mainHomePage .headerShadow {
    background: linear-gradient(rgba(0, 0, 0, .65), rgba(0, 0, 0, .42) 61%, rgba(0, 0, 0, .31) 78%, transparent);
    transition: background .25s linear;
    width: 100%;
    height: 90px;

}

.mainHomePage .header a {
    color: #e6e6e6;
}

/* .header_logo a {} */

.mainHomePage .header a:hover {
    color: #fff;
}

.mainHomePage .header .header_search_circle {
    background-color: rgba(34, 31, 31, .8);
}

.homeMainSection {
    background-image: url('../assets/images/homepagesection1.png');
    background-size: cover;
    background-repeat: no-repeat;
    color: #FFF;
    width: 100%;
    /* min-height: 110vh; */
    height: 100vh;
    padding-bottom: 30px;
    /* margin-top: -90px; */
    padding-top: 81px;
}

.homeMainSection2 {
    background-image: url('../assets/images/homepagesection2.png');
    background-size: cover;
    background-repeat: no-repeat;
    /* color: #FFF;
    width: 100%;
    min-height: 110vh;
    min-height: 100vh;
    padding-bottom:30px;
    margin-top: -90px;
    padding-top: 81px; */
}

/* .sectionOneContent .has_content {
    height: 87vh;
} */

.sectionTwoContent {
    position: relative;
}

/* .sectionTwoContent .has_content {
    height: 84vh;
} */

.homeMainSection .container {
    height: 100%;
}

.homeMainSectionContent {
    height: 100%;
}

.sectionTwoContent .second_img_heading {
    position: absolute;
    letter-spacing: 2px;
    top: 100px;
}

.sectionTwoContent .second_img_heading h5 {
    position: absolute;
    top: -7px;
    left: 11px;
}

.sectionTwoContent .heading_content h1 {
    font-size: 20em;
    padding-top: 0;
}


.heading_content h1 {
    font-size: 96px;
    font-weight: 500;
    letter-spacing: 3px;
    /* padding-top: 28%; */
}

.sticky-header {
    /* position: sticky; */
    position: fixed;
    top: 0;
    z-index: 100;
    /* Make sure the header stays above other content */
}

.homeMainSection3 {
    background-image: url('../assets/images/homepagesection3.png');
    background-size: cover;
    background-repeat: no-repeat;
    /* color: #FFF;
    width: 100%;
    min-height: 110vh;
    min-height: 100vh;
    padding-bottom:30px;
    margin-top: -90px;
    padding-top: 81px; */
}

.sectionThreeContent {
    position: relative;
}

/* .sectionThreeContent .has_content {
    height: 93vh;
} */


.sectionThreeContent .second_img_heading {
    position: absolute;
    letter-spacing: 2px;
    top: 100px;
}

.sectionThreeContent .second_img_heading h5 {
    position: absolute;
    top: 0px;
    left: 6px;
    font-size: 16px;

}

.sectionThreeContent .heading_content h1 {
    padding-top: 60px;
    font-size: 64px;
    margin-bottom: 20px;
}

.sectionThreeContent .heading_content p {
    font-size: 16px;
    margin-bottom: 20px;
}

.sectionThreeContent .heading_content span {
    font-size: 14px;
    color: #fff;
    text-decoration: underline;
    margin-top: 20px;
    letter-spacing: 1px;
}

.all_models_heading::before {
    height: 1px;
    width: 100%;
    background-color: #D6D6D6;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    content: "";
    z-index: -1;
}

.models_btn {
    background-color: #fff;
    border: 1px solid #D6D6D6;
    border-radius: 20px;
    padding: 5px 20px;
    font-size: 14px;
    box-shadow: -1px 9px 7px -5px rgba(0, 0, 0, 0.30);
    -webkit-box-shadow: -1px 9px 7px -5px rgba(0, 0, 0, 0.30);
    -moz-box-shadow: -1px 9px 7px -5px rgba(0, 0, 0, 0.30);
}

.sectionFourContent .text_arrow_btn,
.text_arrow_btn svg {
    color: #000;
}

.sectionFourContent .hm_models_list ul li {
    width: 33.3%;
}

.margin-right-2 {
    margin-right: 20px;
}

.margin-right-3 {
    margin-right: 30px;
}

.input-Form-main .form-control {
    line-height: 2.2;
}

.input-Form-main .form-select {
    line-height: 1.8;
}

.input-Form-main .form-select option {
    font-size: 16px;
}

.financing_main_wrapper .bd_box {
    padding: 40px;
}

.financing_main_wrapper .bd_box hr {
    margin-bottom: 25px;
    margin-top: 25px;
}

.document_section .fs_bodystyle_label {
    width: 100%;
    border: 1px solid #dee2e6;
}

.font-weight-normal {
    font-weight: normal;
}

.areYouSureModal p {
    line-height: 30px;
    font-size: 16px !important;
}


.areYouSureModal .modal-dialog.modal-xl {
    max-width: 1000px;
}

.areYouSureModal .fpi_mainheading,
.areYouSureModal .fpi_quiz_sec {
    padding: 15px 50px 0px 40px;
}

.fpi_mainheading h2 {
    position: relative;
}

.badge {
    font-size: 12px;
    border-radius: 0;
    background-position: -4px;
    position: absolute;
    top: 49px;
    margin-left: 10px;
}

.box.tradeOpt_yourVehBox_content {
    background: #f3f3f3;
    padding: 20px;
}

.box.tradeOpt_yourVehBox_content h2 {
    font-size: 18px;
}

.checkout_menu ul li.active {
    background: #fff;
}

.checkout_menu ul li.active a {
    color: #000;
}

.success-checkmark {
    position: relative;
}

.success-checkmark:after {
    content: '✔';
    position: absolute;
    left: 9px;
    top: -16px;
    width: 15px;
    height: 15px;
    text-align: center;
    border: 1px solid #fff;
    background: #1c69d4;
    border-radius: 50%;
    color: #fff;
    font-size: 8px;
    line-height: 14px;
}

.welcome_guest.after_login {
    display: flex;
    align-items: center;
}

.userLogin_img {
    background-color: var(--color_light_grey2);
    margin: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 14px !important;
    overflow: hidden;
}

.userLogin_img img {
    width: 100%;
    height: 100%;
}

.homeMainSection5 a img {
    display: block;
    margin: auto;
}

.form-check-wrap .form-check label {
    padding-left: 15px;
}

.hidden-scroll {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: hidden;
}

.hidden-scroll::-webkit-scrollbar {
    display: none;
}