@media (min-width: 1200px) and (max-width: 1399.98px) { 
    .contracting_download_btn{ padding: 10px 10px; }

    .leaseForm .d-flex{ flex-direction: column !important; }
    .col-lg-5, .col-lg-7{ width: 100%; }
    .product_detail_grid_right .headline-5{ font-size: 16px; }
    .product_detail_grid_right h2 small{ font-size: 14px !important; }
    .product_detail_grid_right .headline-4 {
        font-size: 24px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) { 
    .main_content_sec .leftSection h2{ font-size: 18px; }
    .main_content_sec .leftSection h1{
        font-size: 36px;
    }
    .pof_nextbtn a button.btn{ width: 162px; padding: 10px 10px; }
    .po_footer_inner p.ms-auto{ width: 164px; padding-left: 10px; }
    .po_content_height{ min-height: calc(100vh - 170px); }

    .headline-4{ font-size: 30px; }
    .headline-6{ font-size: 16px; }

    .contracting_download_btn{ padding: 10px 10px; }

    .bd_your_lease_sec{ flex-wrap: wrap; }
    .dap_radio_box{ width: 100%; }
    .dap_radio_box.margin-right-3, .financing_main_wrapper .margin-right-3{ margin: 0px 0px 30px 0px; }
    

    .homeMainSection5 a img{ width: 100%; }

    .vehicles_list_ul>li{ width: 50%; }

    .leaseForm .d-flex{ flex-direction: column !important; }
    .col-lg-5, .col-lg-7{ width: 100%; }
    .product_detail_grid_right .headline-5{ font-size: 14px; }
    .product_detail_grid_right h2 small{ font-size: 12px !important; }
    .product_detail_grid_right .headline-4 {
        font-size: 20px;
    }

    .filtersHeader_left{ width: 240px; }
    .filtersHeader_right ul>li{ margin-left: 18px; }
    .results_infoheader .me-4.text-end{ margin-right: 10px !important; }
    .results_infoheader .content-2--bold {font-size: 14px;}


}


@media (min-width: 768px) and (max-width: 991.98px) { 
    .leftSection{
        border: 0;
        border-bottom: 1px solid #fff;
        padding-bottom: 20px;
        margin-bottom: 20px;
     }
     .main_content_sec .leftSection h1{
        font-size: 44px;
     }
     .rightSection{ padding: 0; }
     .checkout_logo small{ display: none; }
     .header_menu ul li{ margin-left: 18px; }
     .header a{ font-size: 14px; }
     .header_info .ms-4{ margin-left: 15px !important; }
     .header_inner{ padding: 20px; }

     .po_content_height{ min-height: calc(100vh - 240px); }
     .po_footer_inner{ flex-wrap: wrap; }
     .po_footer_inner .ms-auto span.label-2, .pof_nextbtn{ display: block; text-align: center; padding-top: 10px; }
     .pof_nextbtn{ width: 100%; }
     .content-2--bold{ font-size: 14px; }
     .pof_iconBtn{ margin: 0 !important; }
     .po_footer_inner p.ms-auto{ padding-right: 0px !important; width: 100%; }

    .headline-4{ font-size: 30px; }
    .headline-6{ font-size: 16px; }

     .bd_alert_text{ margin: 0px 0px 30px !important; }
     .bd_your_lease_sec{ flex-wrap: wrap; }
     .dap_radio_box{ width: 100%; }
     .margin-right-3{ margin:0px 0px 20px; }
     .bd_box{ padding: 20px; }
    .extInsurance_logoBox a img{ max-height: 65px; max-width: 110px; }

    .lib_profileLogo{ margin:10px auto 10px; padding-left: 10px; }
    .insu_mu_mid p{ font-size: 12px; }
    .insu_mu_mid img{ width: 60px; }
    .insu_mu_mid{     width: 210px; }
    .insu_mu_co, .insu_mu_bmw{ width: 90px; height: 90px; }
    
    .contracting_box{ margin-bottom: 20px; }

    .po_footer_inner{ position: relative; }
    .po_footer_inner .fade.toast.show{ right: 0px; margin: 0; }

    .mainHomePage .sticky-header .container{ max-width: 100%; padding: 0; }
    .tu_text{ margin: 0 !important; }
    .homeMainSection5 a img{ width: 100%; }

    .financing_main_wrapper .bd_box{ padding: 20px; }

    .filtersHeader_left{ width: 130px; }
    .vehicles_list_ul>li{ width: 100%; }
    .results_infoheader .me-4.text-end{ margin: 0px 0px 0px 20px !important; }


.hi_online{ margin-right: 15px !important; }
.backToResult_text h4{ font-size: 14px; }
.header a.header_location{ font-size: 12px; }

.product_detail_grid_row{ flex-direction: column; }
.product_detail_grid_left, .product_detail_grid_right{ width: 100%; }

.label-1 .accordion-button{ font-size: 13px;padding: 15px 15px; }
.accordion-button::after, .accordion-button:not(.collapsed)::after{ background-size: 12px auto !important; }

.col-lg-5, .col-lg-7{ width: 50%; }
.price_heading_box .headline-5{ font-size: 14px; }
.price_heading_box h2 small{ font-size: 13px; }
.price_heading_box .headline-4 {font-size: 20px; }

.fpi_mainheading, .fpi_quiz_sec{ padding: 0; }
.fpi_list_items ul li a{ font-size: 12px; letter-spacing: 0; }
.fpi_list_items img{ width: 45px; margin-right: 5px;height: 30px; }
.perfpopright{ padding: 30px 15px; }
.perfectpopup{ padding-left: 300px; }
.perfpopimg { width: 300px; }
.modal-dialog { padding: 1rem !important;}
.filtersHeader_right ul>li{ margin-left: 10px }
.filtersHeader{ padding: 0 20px; }

.pfi_step5{ padding: 20px 15px 30px; }
.pfi_step5_box_text h3{ font-size: 18px; }
.pfi_step5_box_text span{font-size: 16px;}
.pfi_step5_box_text p{ font-size: 14px; }
.pfi_step5_box_btn .btn{ padding: 6px; }

.models_popup .nav-justified .nav-item{ padding-right: 20px; }


 }


 @media (max-width: 767.98px) { 
    .header_inner{
        padding:15px 15px;
        min-height: auto;
    }
    .header_inner .content-2--bold{
        font-size: 14px;
    }
    .checkout_sidenav{
        position: fixed;
    }
    .checkoutSideNavMain{
        display: none;
    }
    .checkoutSideNavMain.open{
        display: flex;
        align-items: top;

    }
    .checkoutSideNavMain .dcoCloseBtn{
        position: fixed;
        top: 20px;
        left: 248px;
        z-index: 9;
        padding: 10px;
        display: none;
    }
    .checkoutSideNavMain.open .dcoCloseBtn{
        display: block;
    }
    .leave_button .d-flex.justify-content-end{
        justify-content: space-between !important;
    }
    .po_header_inner{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .checkout_rightContent{
        width: 100%;
        padding: 20px;
    }
    .main_content_sec .leftSection h2{
        font-size: 16px;
    }
    .main_content_sec .leftSection h1{
        font-size: 30px;
    }
    .digital_text{
        font-size: 16px;
    }
    .checkout_rightContent.has_image{
        height: 100vh;
    }
    .checkout_rightContent_inner{
        height: calc(100% - 65px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .footer_pricing_sec{
        position: static;
    }
    .rightSection{
        padding-left: 0px;
    }
    .leftSection{
        border: 0;
        border-bottom: 1px solid #fff;
        padding-bottom: 20px;
        margin-bottom: 20px;
     }
    .footer_pricing_auto{
        width: 100%;
    }
    .footer_pricing_btn .btn{
        padding: 12px 20px;
        font-size: 14px;
    }
    .headline-5{
        font-size: 18px;
    }
    .dcoMenuBtn{
        display: block;
    }
    .welcome_guest{
        padding: 12px 25px;
    }
    .checkout_menu ul{
        padding: 20px 0;
    }
    .checkout_menu ul li .header_location{
        height: 40px;
    }
    .chatBoxSec ul li{
        padding: 10px 40px;
    }

    /* header */
    .headerRightInner{ padding-top: 15px; flex-direction: column-reverse;}
    .header_menu{ padding-top: 15px; }
    .header_menu ul{ justify-content: center; }
    .header_menu ul li:last-child{ margin-left: 0px; }
    .header_menu ul li{ margin:0px 18px 5px 0px; }
    .header a{ font-size: 14px; }
    .header_menu ul li.has_dropdown>a{ padding-right: 20px; }

    .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon{
        opacity: .6;
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16"><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/></svg>') no-repeat center center;
        background-size: 38px;
     }

     .po_footer_inner{ position: relative; }
     .po_footer_inner .fade.toast.show{ right: 0px; margin: 0; }


     /* jam mobile view */

    
     .headline-4{ font-size: 24px; }
     .headline-6{ font-size: 16px; }
     .po_footer{ position: static; margin: 0px -20px;width: calc(100% + 40px); }
     .po_footer_inner{ flex-wrap: wrap; }
     .po_footer_inner .ms-auto span.label-2, .pof_nextbtn{ display: block; text-align: center; padding-top: 10px; }
     .pof_nextbtn{ width: 100%; }
     .content-2--bold{ font-size: 14px; }
     .pof_iconBtn{ margin: 0 !important; }
     .po_footer_inner p.ms-auto{ padding-right: 0px !important; width: 100%; }

     .ins_uploadLicense_row, .bd_your_lease_sec, .lib_profileInfo { flex-wrap: wrap; }
     .bd_alert_text{ margin: 0px 0px 30px !important; }
     .margin-right-3{ margin:0px 0px 20px; }
     .bd_box{ padding: 20px; }
    .extInsurance_logoBox{ width: 50%; }
    .extInsurance_logoBox a img{ max-height: 65px; max-width: 110px; }

    .lib_profileLogo{ margin:10px auto 10px; padding-left: 10px; }
    .insu_mu_mid p{ font-size: 12px; }




    .filter_drawer{ position: fixed; left: 0px; top: 0px; z-index: 999; padding-top: 50px; height: 100%; overflow: hidden; -webkit-overflow-scrolling: initial;  }

    .filter_close_mob{ width: 50px; height: 50px; display: flex; align-items: center; justify-content: center; position: absolute; right: 0px; top: 0px; }

    .filter_drawer_inner{ position: relative; z-index: 2; overflow-y: auto; overflow-x: hidden;  -webkit-overflow-scrolling: touch;  scrollbar-width: none;  background-color: #fff; max-height: calc(100vh - 80px); }

    .mob_filter_overlay{ width: 100%; height: 100%; position: fixed; left: 300px; top: 0px; background-color: rgba(0, 0, 0, 0.15); display: block; }

    .filtersHeader{ padding: 0px 15px; }

.filtersHeader_left{ width: auto;
    min-width: 120px;
    padding: 0 15px;
    height: 45px;
    border-radius: 27.5px;
    -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,.4);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.4);
    background-color: #221f1f;
    color: #fff;
    position: fixed;
    bottom: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 100;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
    
.showHideFilterButton{ color: #fff;}
.filtersHeader_right > ul{ text-align: left; justify-content: center !important; margin: 0px -8px; }
.filtersHeader_right ul>li{ margin: 0px; padding: 8px 6px; width: 50%; padding: 4px 6px;}

.filtersH_link{  justify-content: flex-start;}

.filtersH_link svg, .filtersH_link i{ display: block;}


/* review and sign */
.leave_btn .btn{ padding: 10px 12px; }
.btn{ padding: 10px 15px; }
.signature_sec{ padding-left: 40px; width: auto; }

.fp_date_box{ padding: 10px 55px 10px 10px; }
.fp_date_next{ right: 10px; } 
.fp_time_btn{ padding: 10px; }
.fp_time_main ul li{ width: 50%; }


.pd_modal{
    padding: 15px;
}
.pd_detail { flex-direction: column; }
.pd_detail_right{ width: 100%; }
.pd_detail_text{ padding: 0px 8px; }



.mainHomePage .navbar-toggler{ background-color: #fff; }
.mainHomePage .sticky-header .container { padding: 0px; }
.mainHomePage .header_inner{ padding: 0; }
.mainHomePage .headerRight{ padding:10px 15px 0; background-color: #fff; margin: 0px -15px; }
.mainHomePage .header{ padding: 15px; background-color: #fff}
.mainHomePage .header a{ color: var(--color_grey); }
.mainHomePage .header .header_search_circle { background-color: var(--color_light_grey2); }
.mainHomePage .headerShadow{ height: 80px; }

.homeMainSection{ margin-top: 0;min-height: 100vh; }
.heading_content h1{ font-size: 36px; }
.sectionTwoContent .second_img_heading h5{ position: static; }
.sectionTwoContent .heading_content h1{ font-size: 10em;line-height: .8em; }
.sectionThreeContent .heading_content h1{ font-size: 36px; padding-top: 0px; }
.sectionThreeContent .second_img_heading h5{ position: static; }
.sectionThreeContent .second_img_heading{ position: static; }
.sectionFourContent .hm_models_list ul li{ width: 50%; }
.text_arrow_btn{ font-size: 14px; }

.homeMainSection5 a img{ width: 100%; }

.financing_main_wrapper .bd_box{ padding: 15px; }

.results_infoheader .me-4.text-end{ margin-right: 0px !important; }
.results_infoheader .btn{ margin-left: 10px; }

.vehicles_list_ul>li{ width: 100%; }

.filtersH_link span{ 
    word-break: break-word;
    display: block;
    white-space: break-spaces;
    text-align: left;
}


.HeaderRightInner{ flex-direction: column; padding-top: 10px; }
.header_info_inner{ padding-top: 10px; flex-wrap: wrap; justify-content: center !important; text-align: center; }
.hi_location, .hi_online{ flex: 0 0 100%; margin: 0 !important; padding-bottom: 10px; }
/* .header_location{ justify-content: center; } */


.product_detail_grid_row{ flex-direction: column; }
.product_detail_grid_left, .product_detail_grid_right{ width: 100%; }

.label-1 .accordion-button{ font-size: 13px;padding: 15px 15px; }
.accordion-button::after, .accordion-button:not(.collapsed)::after{ background-size: 12px auto !important; }

.leaseForm .d-flex{ flex-direction: column !important; }
.col-lg-5, .col-lg-7{ width: 100%; }
.price_heading_box .headline-5{ font-size: 14px; }
.price_heading_box h2 small{ font-size: 13px; }
.price_heading_box .headline-4 {
    font-size: 20px;
}


.perfectpopup{ padding: 0px; }
.perfpopimg{ display: none; }
.fpi_mainheading, .fpi_quiz_sec{ padding: 0px; }
.fpi_mainheading h2{ font-size: 22px; }
.subheading h3{ font-size: 16px; }
.fpi_list_items ul li{ width: calc(50% - 10px); }

.hm_models_heading h1{ font-size: 20px; }
.hm_col3 .hm_models_list ul li, .hm_models_list ul li{ width: 50%; }

.models_popup .nav-tabs.nav-justified{ border: 0px; }
.models_popup .nav-tabs .nav-link{padding: 0px 0px 8px;}
.models_popup .nav-justified .nav-item{ border-bottom: 1px solid #cacaca;margin-bottom: 10px; flex-grow: 1; }

.filtersHeader_inner .fade.toast.show{ margin-left: 0px; width: 300px; }






}

 @media (max-width: 575.98px) { 
    .ins_uploadLicense_row .col{ width: 100%;    flex: 1 0 100%; }
    .insu_mu_bmw, .insu_mu_co{ width: 60px; height: 60px; }
    .insu_mu_mid img{ width: 40px; }
    .insu_mu_mid{ width: 150px; }
    /* .insu_mu_logos{ flex-direction: column; } */
    .signature_sec{ width: 100%; }
    .fp_time_main ul li{ width: 100%; }
    .fpi_list_items ul li{ width: 100%; }
    .hm_col3 .hm_models_list ul li, .hm_models_list ul li{ width: auto; }

    .results_infoheader{ flex-direction: column; }
    .results_infoheader .col{ width: 100%; display: block !important; }
    .results_infoheader .me-4.text-end{ text-align: left !important; padding-top: 15px; }
    
 }
