.container {
    max-width: 1320px;
    margin: auto;
    width: 100%;
    padding: 0px 20px;
}

.footer_row {
    display: flex;
    flex-wrap: wrap;
}

.leftheading {
    width: 10%;
}

.leftheading h4,
.footleft p {
    font-size: 13px;
    color: #221f1f;
    text-transform: capitalize;
    letter-spacing: 0.5px;
    font-weight: 400;
    display: inline;
}

.rightitems {
    display: flex;
}

.seriesmain {
    display: flex;
    justify-content: center;
    padding: 28px 0px;

}

.seriesmain ul {
    display: flex;
    flex-wrap: wrap;
}

.seriesmain li a {
    text-transform: none;
    text-decoration: none;
    padding-left: 35px;
    font-size: 13px;
    text-transform: capitalize;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: #221f1f;
}

.serieshead{ width: 100%; background-color: #fff;}
 
.footer {
    background-color: #f6f6f6;
}

.footer_bottom{ width: 100%; background-color: #fff;}

.footer_col {
    width: 20%;
    padding: 0 15px 30px 0px;
    margin-top: 40px;
}

.footer_col h4 {
    font-size: 13px;
    color: #221f1f;
    text-transform: capitalize;
    margin-bottom: 30px;
    letter-spacing: 0.5px;
    font-weight: 400;
    position: relative;
}

.footer_col ul li:not(:last-child) {
    margin-bottom: 18px;
}

.footer_col ul li a {
    font-size: 13px;
    text-transform: capitalize;
    text-decoration: none;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 17px;
    color: #221f1f;
    display: block;
}

.footright{ width: auto;}

.footright ul {
    display: flex;
}

.footright ul li a {
    font-size: 13px;
    text-transform: capitalize;
    text-decoration: none;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 17px;
    color: #221f1f;
    padding-right: 20px;
    display: block;
}

.footer_col img {
    height: auto;
    width: 100px;
    margin-bottom: 30px;
}

.footer_col ul li p {
    font-size: 13px;
    letter-spacing: 0.5px;
}

.footer_col ul li a:hover,
.rightitems ul li a:hover,
.footright ul li a:hover,
.social_links a i:hover {
    color: #1c69d3;
}

.social_links {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

}
.social_links a{ display: block; }

.brands{ font-size: 22px; line-height: 24px; display: block; color: #221f1f; transition: all 0.3s ease;}

.footerlabel {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
}

@media (min-width: 768px) and (max-width: 1023px) {
    .seriesmain{ display: block; padding-top: 20px; padding-bottom: 10px;}
    .leftheading{ width: 100%; padding-bottom: 10px; }
    .rightitems ul{ flex-wrap: nowrap; overflow-x: auto;  }
    .rightitems ul li a{ padding-left: 0px; margin-right: 30px; margin-bottom: 10px; display: block; white-space: nowrap; } 
    .footer_col ul li a{ font-size: 12px; }

}

@media (max-width: 767.98px) { 
    .footer_col { width: 50%;  margin-bottom: 0px; padding-bottom: 0px; }
    .footer_col h4{  margin-bottom: 20px; }
    .footer_col ul li{ margin-bottom: 10px; }

    .seriesmain{ display: block; padding-top: 20px; padding-bottom: 10px;}
    .leftheading{ width: 100%; padding-bottom: 10px; }
    .rightitems ul{ flex-wrap: nowrap; overflow-x: auto;  }
    .rightitems ul li a{ padding-left: 0px; margin-right: 30px; margin-bottom: 10px; display: block; white-space: nowrap; } 
    
}

@media(max-width: 574px) {
    .footer_col { width: 100%; }
    .footleft{ padding-bottom: 10px; text-align: center; width: 100%; }
}