/* Model popup section */
.models_popup {
    padding: 0 !important;
    background: #f6f6f6;
}
.models_popup .modal-content{ 
    background: #f6f6f6;
    border: 0;
 }
.models_popup .modelTabs{
    margin: 0;
    border: 0;
    position: relative;
 }
.models_popup .modal-body{ 
    padding: 0;
}
.models_popup .modal-header{
 padding: 0;
 border: 0;
}
.models_popup .btn-close{
    position: fixed;
    display: block;
    width: 50px;
    height: 50px;
    right: 40px;
    top: 40px;
    background-color: #fff;
    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
    z-index: 500;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    opacity: 1;
}
.models_popup .btn-close:hover{
    transition: background-color .3s ease-in-out;
    background-color: #f6f6f6;
}
.models_popup .nav-justified .nav-item{
    flex-basis: inherit;
    flex-grow: inherit;
    padding-right: 25px;
}
.models_popup .nav-justified .nav-item:nth-child(6){
    padding-left: 25px;
    position: relative;
}
.models_popup .nav-justified .nav-item:nth-child(6):before{
    content: "";
    width: 1px;
    height: 20px;
    background: #cacaca;
    position: absolute;
    left: 0;
    top: 0;
}
.models_popup .nav-tabs .nav-link{
    padding: 0px 0px 14px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: .25px;
    font-weight: 700;
    color: #727171;
}
.models_popup .nav-tabs .nav-link.active{
    color: #221f1f;
    border-bottom-color: #221f1f !important;
}
.models_popup .nav-tabs.nav-justified{
    border-bottom-color: #cacaca;
}
.inventory_btn{
    font-size: 13px;
    line-height: 16px;
    letter-spacing: .25px;
    padding: 12px 30px;
    background-color: #1c69d3;
    border-radius: 0 !important;
    color: #fff !important;
    position: absolute;
    right: 0;
    top: -15px;
}
.inventory_btn:hover{
    background-color: #1d55a2;
    transition: all .3s ease-in-out;
}

.hm_models_auto {
    max-width: 2000px;
    width: 100%;
    margin: auto;
    padding: 120px 12.495% 0;
}
.hm_models_section {
    width: 100%;
    padding: 50px 0px 50px;
}
.hm_models_heading {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 50px;
}
.hm_models_heading h1 img {
    max-width: 60px;
    display: block;
    height: auto;
    margin-bottom: 10px;
}
.hm_models_heading h1 {
    font-size: 40px;
    line-height: 1em;
    color: #221f1f;
    font-weight: 400;
    text-transform: uppercase;
    font-family: BMWTypeNext,Helvetica Neue,-apple-system,"system-ui",BlinkMacSystemFont,serif;
}
.hm_models_heading h1 a {
    font-size: 16px;
    line-height: 20px;
    color: #221f1f;
    letter-spacing: .25px;
    font-weight: 700;
    margin-left: 15px;
    transition: all 0.3s ease;
}
.hm_models_heading h1 a:hover {
    color: rgba(34, 31, 31, .7);
}
.hm_models_heading span {
    font-size: 28px;
    line-height: 1.5em;
    letter-spacing: -.1px;
    color: #727171;
    font-weight: 300;
    display: flex;
    align-items: center;
}
.hm_models_heading span i {
    padding-left: 5px;
}
.hm_models_heading span i img {
    max-width: 46px;
    display: block;
    height: auto;
}
.hm_col3 .hm_models_heading h1{
    text-transform: capitalize;
}
.hm_models_list ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}
.hm_models_list ul li {
    width: 16.66%;
}
.hm_col3 .hm_models_list ul li {
    width: 25%;
}
.hm_models_box {
    width: 100%;
    padding: 25px 0px 18px;
    text-decoration: none;
    display: block;
    transition: all 0.3s ease;
}
.hm_models_box img {
    max-width: 80%;
    width: 100%;
    display: block;
    height: auto;
    margin: auto;
}
.hm_models_box b {
    font-size: 28px;
    line-height: 1.2em;
    font-weight: 700;
    display: block;
    text-align: center;
    color: #221f1f;
    padding-top: 10px;
}
.hm_col3 .hm_models_box b {
    font-size: 13px;
}
.hm_models_box:hover {
    background: #fff;
    box-shadow: 0 20px 22px -3px rgba(0, 0, 0, .08);
}
.hm_future_section {
    width: 100%;
    padding-top: 70px;
    margin-top: 50px;
    border-top: 1px solid #cacaca;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.hm_future_left {
    width: 42%;
    position: relative;
}
.hm_future_left a {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
}
.hm_future_right {
    width: 25%;
}
.hm_future_title {
    width: 100%;
    padding-bottom: 40px;
}
.hm_future_title span {
    font-size: 12px;
    line-height: 1.5em;
    display: block;
    color: #221f1f;
    letter-spacing: 2.75px;
    margin-bottom: 15px;
}
.hm_future_title strong,
.hm_future_title a {
    font-size: 20px;
    line-height: 1.5em;
    display: inline-block;
    color: #000;
    transition: all 0.3s ease;
    font-weight: 700;
    text-decoration: none;
    transition: all 0.3s ease;
}
.hm_future_title a:hover {
    color: rgba(0, 0, 0, .7);
}
.hm_future_img {
    width: 100%;
}
.hm_future_img img {
    width: 100%;
    display: block;
    height: auto;
    max-width: 100%;
}
.models_popup_dark .modal-content{
    background: url(../assets/images/Global_Nav_Vehicle_Flyout-Performance_BG.avif) no-repeat top left;
    background-size: cover;
}
.models_popup_dark.models_popup .nav-tabs .nav-link{
    color: #cacaca;
}
.models_popup_dark.models_popup .nav-tabs .nav-link.active{
    border-bottom-color: #cacaca !important;
}
.models_popup_dark .hm_models_heading h1, .models_popup_dark .hm_models_heading h1 a, .models_popup_dark .hm_models_box b{
 color: #fff;
}
.models_popup_dark .hm_models_box:hover{
    background: linear-gradient(-40deg,hsla(0,0%,100%,.11),transparent 50%),linear-gradient(140deg,hsla(0,0%,100%,.11),transparent 50%),hsla(0,0%,100%,.07);
}
.models_popup_dark .nav-tabs.nav-justified{
    border-bottom-color: #727171;
}

/* Model popup section end */

/* perfect modal step 5 start */

.pfi_step5{
    background: #F2F2F2;
    padding: 20px 70px 50px;
}

.fpi_mainheading h2 .try_again_icon_btn{
    position: absolute;
    left: 0;
    top: 8px;
}

.try_again_icon_btn{
    font-size: 14px;
    line-height: 1.3em;
    letter-spacing: 0.42px;
    color: #000;
    transition: all 0.3s ease;
    max-width: 100px;
}

.pfi_step5_box{
    width: 100%;
}

.pfi_step5_box figure img{
    display: block;
}

.pfi_step5_box_text{
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
}
.pfi_step5_box_text h3{
    color: #000;
    font-size: 24px;
    line-height: 1.3em;
    font-weight: 500;
    letter-spacing: 0.72px;
}
.pfi_step5_box_text small{
    color: #000;
    font-size: 14px;
    line-height: 1.3em;
    letter-spacing: 0.42px;
    display: block;
}
.pfi_step5_box_text span{
    color: #000;
    font-size: 20px;
    line-height: 1.3em;
    letter-spacing: 0.42px;
    display: block;
}
.pfi_step5_box_text p{
    color: #000;
    font-size: 15px;
    line-height: 1.3em;
    letter-spacing: 0.45px;
    text-align: left;
    padding-top: 6px;
}
.pfi_step5_box_btn .btn{
    width: 50%;
    padding: 10px;
}

/* Finalize In-Person Modal */
.finalizeInPersonModal .mg_modal-left-content{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 60px 40px;
    height: 100%;
}
.finalizeInPersonModal .mg_modal-left-content h1{
    font-size: 34px;
    line-height: 1.2em;
}
.fp_modal_left_text p{
    font-size: 14px;
    line-height: 1.5em;
    letter-spacing: .25px;
}
.fp_modal_left_text p a{
    transition: all 0.3s ease;
}
.fp_modal_left_text p a:hover{
    color: #1C69D4 !important;
}

.fp_right{
    width: 100%;
    padding:60px 40px;
}

.fp_right_content{
    width: 100%;
    min-height: 240px;
    padding-bottom: 30px;
}

.fp_right_footer span{
    font-size: 13px;
    line-height: 1.3em;
    letter-spacing: .25px;
    display: block;
}
.fp_right_footer button.btn{
    padding: 10px 20px;
}

.fp_step1_box{
    width: 100%;
    background: #F6F6F6;
    border-radius: 3px;
    padding: 20px;
}

.fp_step1_box_img img{
    max-width: 150px;
    width: 100%;
    display: block;
    height: auto;
    margin-left: auto;
}
.fp_step1_box_heading .content-3--regular-grey{
    font-size: 12px;
}

.fp_step1_box_heading .headline-6 span{
    font-weight: 400;
}

.finalizeInPersonModal .modal-header{
    padding: 0;
    border: 0;
}
.finalizeInPersonModal .btn-close{
    position: absolute;
    display: block;
    width: 50px;
    height: 50px;
    right: 10px;
    top: 10px;
    background-color: #fff;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    opacity: 1;
    z-index: 500;
}
.finalizeInPersonModal .btn-close:hover{
    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
    background-color: #f6f6f6;
}

.fp_date_box{
    width: 100%;
    border-radius: 3px;
    padding: 10px 70px 10px 20px;
    background: #fff;
}

.fp_date_box_inner{
    overflow-x: auto;
    padding: 10px 0px 10px;
}

.fp_date_box ul li{
    padding: 0px 2px;
}

.fp_date_btn{
    width: 50px;
    font-size: 16px;
    line-height: 1.3em;
    font-weight: 700;
    display: block;
    transition: all 0.3s ease;
    padding: 5px 10px;
    border-radius: 3px;
    letter-spacing: .5px;
    text-align: center;
}
.fp_date_btn span{
    color: var(--color_grey2);
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: .5px;
    font-weight: 400;
    display: block;
    transition:all 0.3s ease;
}
.fp_date_btn:hover, .fp_date_btn.active{
    background-color: #000;
    color: #fff;
}
.fp_date_btn:hover span, .fp_date_btn.active span{
    color: #fff;
}

.fp_date_next{
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(0, -50%);
}

.fp_date_next_btn{
    width: 35px;
    height: 35px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition:all 0.3s ease;
    
}
.fp_date_next_btn svg path{
    fill: #3E3E3E;
    transition:all 0.3s ease;
}

.fp_date_next_btn:hover, .fp_date_next_btn.active{
    background: #000;
}
.fp_date_next_btn:hover svg path, .fp_date_next_btn.active svg path{
    fill: #fff;
}

.fp_time_main ul{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -3px;
}
.fp_time_main ul li{
    padding: 3px;
    width: 50%;
}
.fp_time_btn{
    width: 100%;
    font-size: 14px;
    line-height: 1.3em;
    font-weight: 400;
    display: block;
    transition: all 0.3s ease;
    padding: 10px 20px;
    border-radius: 3px;
    letter-spacing: .5px;
    text-align: center;
}
.fp_time_btn:hover, .fp_time_btn.active{
    border-color: #000 !important;
}
.fp_expect_text span{
    font-size: 14px;
    line-height: 1.3em;
    font-weight: 700;
    color: #000;
    display: block;
    padding-bottom: 5px;
    letter-spacing: .5px;
}
.fp_expect_text ul{
    padding-left: 20px;
}
.fp_expect_text ul li{
    list-style: disc;
    font-size: 14px;
    line-height: 1.5em;
    font-weight: 400;
    color: #000;
}

.fp_step3_date_main{
    display: flex;
    align-items: center;
}
.fp_step3_date{
    width: 50px;
    background: #fff;
}
.fp_step3_date small{
    font-size: 12px;
    line-height: 1.3em;
    font-weight: 400;
    color: #fff;
    letter-spacing: .5px;
    text-align: center;
    background: #1C69D4;
    display: block;
}
.fp_step3_date b{
    font-size: 14px;
    line-height: 1.3em;
    font-weight: 700;
    color: #000;
    letter-spacing: .5px;
    text-align: center;
    display: block;
    padding: 5px 0;
}
.fp_step3_address{
    padding-left: 20px;
}
.fp_step3_address strong{
    font-size: 14px;
    line-height: 1.3em;
    font-weight: 700;
    color: #000;
    display: block;
}
.fp_step3_address a{
    font-size: 14px;
    line-height: 1.3em;
    font-weight: 400;
    color: #3E3E3E;
}
.fp_step3_address a svg{
    max-width: 16px;
    margin-right: 5px;
}

/* Review Deal Slider */
.rd_slider{
    position: relative;
    padding-bottom: 100px;
    margin-right: -25px;
}
.rd_slider .react-horizontal-scrolling-menu--arrow-left, .rd_slider .react-horizontal-scrolling-menu--arrow-right{
    width: 30px;
    height: 30px;
    position: absolute;
    left: 0;
    bottom: 50px;
    border: 1px solid #cacaca;
    border-radius: 3px;
}
.rd_slider .react-horizontal-scrolling-menu--arrow-left div, .rd_slider .react-horizontal-scrolling-menu--arrow-right div{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.rd_slider .react-horizontal-scrolling-menu--arrow-left svg, .rd_slider .react-horizontal-scrolling-menu--arrow-right svg{
    max-width: 14px;
}
.rd_slider .react-horizontal-scrolling-menu--arrow-right{
    left: 40px;
}
/* width */
.rd_slider .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
/* Track */
.rd_slider .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar-track {
    background: #F6F6F6;
    border-radius: 5px;
}
/* Handle */
.rd_slider .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}
/* Handle on hover */
.rd_slider .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar-thumb:hover {
    background: #555; 
}


.rd_slide{
    min-height: 350px;
    height: 100%;
}
.rd_slide1{
    width: 700px;
}
.rd_slide1_sec{
    width: 100%;
    height: 100%;
    background: #F6F6F6;
    padding: 30px;
}
.rd_slide1_head::before{
    content: "";
    width: 180px;
    height: 1px;
    background: #cacaca;
    position: absolute;
    left: 0px;
    bottom: -20px;
}
.rd_slide1_head p span{
    padding-right: 10px;
}
.rd_slide1_img img{
    max-width: 350px;
    width: 100%;
    margin-left: auto;
    display: block;
    height: auto;
}
.rd_slide2_img img{
    display: block;
    height: 100%;
    max-height: 350px;
    object-fit: cover;
}

.bd_box{
    width: 100%;
    padding: 30px;
    border-radius: 3px;
}

.bd_news_badge{
    padding: 5px 10px;
    background: #E8F2EB;
}
.bd_alert_text{
    padding-left: 30px;
    position: relative;
}
.bd_alert_text svg{
    position: absolute;
    left: 0;
    top: 0;
}
.bd_tradin_img img{
    max-width: 150px;
    display: block;
    height: auto;
    width: 100%;
}
.bd_tradin_sec{
    padding: 30px 0;
}
.bd_tradin_badge{
    max-width: 35px;
    position: absolute;
    left: 30px;
    top: 0;
}
.bd_tradin_badge img{
    max-width: none;
    display: block;
    height: auto;
    width: 100%;
}
.bd_square_img{
    width: 100px;
    height: 100px;
}
.bd_square_img img{
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.bd_exc_badge{
    border-radius: 3px;
    background: #E6E6E6;
    padding:5px 5px;
    margin-bottom: 5px;
    display: inline-block;
}

.bd_breakdown .acc_title{
    font-size: 12px;
    letter-spacing: 0px;
}

.v_time_btn{
    padding:5px 10px;
    border-radius: 5px;
    line-height: 1em;
}
.v_time_btn b{
    line-height: 1.2em;
}
.v_time_btn svg path{
    fill: #1C69D4;
}

.c_dark_box{
    width: 100%;
    background: #262626;
}
.c_dark_box_inner ul {
    padding-left: 20px;
}
.c_dark_box_inner ul li{
    list-style: disc;
}
.c_dark_box_inner span{
    letter-spacing: 1.5px;
}

.c_dark_box_img img{
    max-width: 300px;
    display: block;
    width: 100%;
    height: auto;
}
.contracting_box .bd_tradin_img img{
    max-width: 45px;
}
.c_doc_box{
    width: 100%;
    padding: 15px;
    border-radius: 3px;
}
.dp_delivery .fp_step3_date{
    width: 80px;
}
.dp_delivery .fp_step3_date small{
    font-size: 14px;
}
.dp_delivery .fp_step3_date b{
    font-size: 20px;
}
.contracting_download ul{
    padding-left: 20px;
    margin-left: 30px;
}
.contracting_download ul li{
    list-style: decimal;
}

.dcoMenuBtn, .dcoCloseBtn{
    display: none;
}

.pd_modal{
    padding: 30px;
}
.pd_detail figure{
    max-width: 138px;
    width: 100%;
    display: block
}
.pd_detail figure img{
    display: block;
}
.pd_detail_text {
    width: 50%;
    padding:0px 15px;
}
.pricingDetalModalMain .modal-dialog { max-width: 600px; }

.pd_detail_right{
    width: calc(100% - 138px);
}

.pd_detail_text ul li span{
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin-right: 8px;
    flex-shrink: 0;
}
.pd_detail_text ul li span.black{
    background: #161616;
}
.pd_info .bd_breakdown .acc_title{
    font-size: 13px;
    text-transform: capitalize;
}
.pd_info_sublist .acc_title{
    font-size: 12px !important;
    color: #222;
}

.pd_info .acc_title .help_icon{
    color: rgba(0,0,0,.5);
}

.pd_tooltip.tooltip_box{ 
    padding-left: 30px;
    color: #222;
 }
 .pd_tooltip.tooltip_box li{
    list-style: disc;
    color: #222;
}

.pd_info_row_bg{
    background: var(--color_light_grey2);
}
.plus_icon{
    max-width: 13px;
    max-height: 13px;
    color: rgba(0,0,0,.5);
}

