.backToResult{ display: inline-flex; padding-left: 40px;}

.backToResult a{ display: flex !important; align-items: center; gap: 8px;  color: #545454; }

.backToResult_text{ display: block;}

.backToResult_text h4{ color: #545454; font-size: 19px; line-height: 1.4em; font-weight: 400; }

.backToResult_text small{ display: block; color: #1B7B3A; font-size: 11px; line-height: 1.4em; font-weight: 700; }


@media (min-width: 768px) and (max-width: 991.98px) { 
    .backToResult_text h4{ font-size: 14px !important; }
}

@media (max-width: 767.98px) {

.backToResult{ padding-left: 0px !important; }
.backToResult_text h4{ font-size: 14px !important; }
.backToResult_text small{ font-size: 11px !important; }

}









