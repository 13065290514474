
.dealerShip_switch{ display: inline-flex; align-items: center;  cursor: pointer; }

.dealerShip_switch_shap{ display: inline-block; width: 48px; height: 24px; margin-right: 10px; position: relative; background-color: #f6f6f6; border-radius: 2px; -webkit-transition: all .2s ease-out;  transition: all .2s ease-out; font-style: normal; cursor: pointer; }

.dealerShip_switch_shap:after{ position: absolute; content: "x"; color: #fff; color: hsla(0,0%,100%,.5);
height: 30px; width: 30px; left: 0; line-height: 28px; top: -3px;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-ms-flex-pack: distribute;
justify-content: space-around;
background-color: #221f1f;
border-radius: 2px;
-webkit-box-shadow: 0 0 5px rgba(114,113,113,.7);
box-shadow: 0 0 5px rgba(114,113,113,.7);
-webkit-transition: all .2s ease-out;
transition: all .2s ease-out; }

.dealerShip_switch_label{ font-size: 11px; line-height: 1.4em; letter-spacing: .80px; color: var(--color_grey); transition: all .3s ease-in-out; font-weight: 700; }



.dealerShip_switch.active .dealerShip_switch_shap:after{  -webkit-transform: translateX(18px);  transform: translateX(18px); background-color: #fff !important; content: ''; background: url(../../assets/images/icon-check--blue.svg) no-repeat center center; }